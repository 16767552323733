import { createReducer } from 'redux-create-reducer'
import callAPI from 'utils/callAPI'

export const REQUEST_LISTING_MANAGEMENT = 'REQUEST_LISTING_MANAGEMENT'
export const REQUEST_LISTING_MANAGEMENT_SUCCESS = 'REQUEST_LISTING_MANAGEMENT_SUCCESS'
export const REQUEST_LISTING_MANAGEMENT_FAILURE = 'REQUEST_LISTING_MANAGEMENT_FAILURE'

export const REQUEST_LISTING_PROPERTY_TYPE = 'REQUEST_LISTING_PROPERTY_TYPE'
export const REQUEST_LISTING_PROPERTY_TYPE_SUCCESS = 'REQUEST_LISTING_PROPERTY_TYPE_SUCCESS'
export const REQUEST_LISTING_PROPERTY_TYPE_FAILURE = 'REQUEST_LISTING_PROPERTY_TYPE_FAILURE'

export const SEARCH_ACTION = 'SEARCH_ACTION'

const defaultState = {
  data: [],
  fetchingListing: true,
  failedFetchingListing: false,

  listPropertyType: [],
  fetchingListPropertyType: false,
  fetchingListPropertyTypeFailed: false,
}

const reducer = createReducer(defaultState, {
  [REQUEST_LISTING_MANAGEMENT]: (state) => {
    return {
      ...state,
      fetchingListing: true,
      failedFetchingListing: false,
    }
  },
  [REQUEST_LISTING_MANAGEMENT_SUCCESS]: (state, action) => {
    return {
      ...state,
      data: action.payload,
      fetchingListing: false,
      failedFetchingListing: false,
    }
  },
  [REQUEST_LISTING_MANAGEMENT_FAILURE]: (state) => {
    return {
      ...state,
      fetchingListing: false,
      failedFetchingListing: true,
    }
  },

  [REQUEST_LISTING_PROPERTY_TYPE]: (state) => {
    return {
      ...state,
      fetchingListPropertyType: true,
      fetchingListPropertyTypeFailed: false,
    }
  },
  [REQUEST_LISTING_PROPERTY_TYPE_SUCCESS]: (state, action) => {
    const { data } = action.payload
    const modifyData = data.map((item) => ({
      value: item,
      title: item,
    }))
    return {
      ...state,
      listPropertyType: modifyData,
      fetchingListPropertyType: false,
      fetchingListPropertyTypeFailed: false,
    }
  },
  [REQUEST_LISTING_PROPERTY_TYPE_FAILURE]: (state) => {
    return {
      ...state,
      fetchingListPropertyType: false,
      fetchingListPropertyTypeFailed: true,
    }
  },
})

export default reducer
export const namespace = 'listingManagement'

export const searchAction = (filters) => ({
  type: SEARCH_ACTION,
  payload: filters,
})

export const fetchListingManagement = (filters) => async (dispatch, getState) => {
  return await callAPI({
    method: 'get',
    apiPath: '/listing',
    actionTypes: [
      REQUEST_LISTING_MANAGEMENT,
      REQUEST_LISTING_MANAGEMENT_SUCCESS,
      REQUEST_LISTING_MANAGEMENT_FAILURE,
    ],
    variables: { ...filters },
    dispatch,
    getState,
  })
}

export const fetchListingPropertyType = () => async (dispatch, getState) => {
  return await callAPI({
    method: 'get',
    apiPath: '/listing/property-type',
    actionTypes: [
      REQUEST_LISTING_PROPERTY_TYPE,
      REQUEST_LISTING_PROPERTY_TYPE_SUCCESS,
      REQUEST_LISTING_PROPERTY_TYPE_FAILURE,
    ],
    dispatch,
    getState,
  })
}
