import { createReducer } from 'redux-create-reducer'
import callAPI from '../../../utils/callAPI'

export const VENDOR_CREATE_ACTION = 'VENDOR_CREATE_ACTION'
export const VENDOR_CREATE_REQUEST = 'VENDOR_CREATE_REQUEST'
export const VENDOR_CREATE_REQUEST_SUCCESS = 'VENDOR_CREATE_REQUEST_SUCCESS'
export const VENDOR_CREATE_REQUEST_FAILURE = 'VENDOR_CREATE_REQUEST_FAILURE'

export const VENDOR_CREATE_UPLOAD_PHOTO_ACTION = 'VENDOR_CREATE_UPLOAD_PHOTO_ACTION'
export const VENDOR_CREATE_UPLOAD_PHOTO_REQUEST = 'VENDOR_CREATE_UPLOAD_PHOTO_REQUEST'
export const VENDOR_CREATE_UPLOAD_PHOTO_REQUEST_SUCCESS =
  'VENDOR_CREATE_UPLOAD_PHOTO_REQUEST_SUCCESS'
export const VENDOR_CREATE_UPLOAD_PHOTO_REQUEST_FAILURE =
  'VENDOR_CREATE_UPLOAD_PHOTO_REQUEST_FAILURE'

const defaultState = {
  vendorInfo: {
    name: '',
    thumbnail: '',
    description: '',
    token: '',
    status: '',
  },
  fetchingCreateVendor: false,
  fetchingCreateVendorFailed: false,
}

const reducer = createReducer(defaultState, {
  [VENDOR_CREATE_REQUEST]: (state, action) => {
    return {
      ...state,
      fetchingCreateVendor: true,
      fetchingCreateVendorFailed: false,
    }
  },
  [VENDOR_CREATE_REQUEST_SUCCESS]: (state, action) => {
    return { ...defaultState }
  },
  [VENDOR_CREATE_REQUEST_FAILURE]: (state, action) => {
    return {
      ...state,
      fetchingCreateVendor: false,
      fetchingCreateVendorFailed: true,
    }
  },

  [VENDOR_CREATE_UPLOAD_PHOTO_REQUEST_SUCCESS]: (state, action) => {
    return {
      ...state,
      vendorInfo: {
        ...state.vendorInfo,
        thumbnail: action.payload.data[0].link,
      },
    }
  },
})

export default reducer
export const namespace = 'vendorManagementCreate'

export const fetchCreateVendor = (vendorInfo) => async (dispatch, getState) => {
  return await callAPI({
    method: 'post',
    apiPath: '/vendor-category',
    actionTypes: [
      VENDOR_CREATE_REQUEST,
      VENDOR_CREATE_REQUEST_SUCCESS,
      VENDOR_CREATE_REQUEST_FAILURE,
    ],
    variables: { ...vendorInfo },
    dispatch,
    getState,
  })
}

export const fetchCreateVendorUploadPhoto = (file) => async (dispatch, getState) => {
  let formData = new FormData()
  formData.append('file', file)
  return await callAPI({
    method: 'post',
    apiPath: '/file/upload-files',
    actionTypes: [
      VENDOR_CREATE_UPLOAD_PHOTO_REQUEST,
      VENDOR_CREATE_UPLOAD_PHOTO_REQUEST_SUCCESS,
      VENDOR_CREATE_UPLOAD_PHOTO_REQUEST_FAILURE,
    ],
    variables: formData,
    dispatch,
    getState,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export const createVendorAction = (payload) => ({
  type: VENDOR_CREATE_ACTION,
  payload,
})

export const uploadPhotoCreateVendorAction = (credentials) => ({
  type: VENDOR_CREATE_UPLOAD_PHOTO_ACTION,
  payload: credentials,
})
