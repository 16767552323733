import { createReducer } from 'redux-create-reducer'
import callAPI from 'utils/callAPI'
export const REQUEST_DETAILS_REPORTED = 'REQUEST_DETAILS_REPORTED'
export const REQUEST_DETAILS_REPORTED_SUCCESS = 'REQUEST_DETAILS_REPORTED_SUCCESS'
export const REQUEST_DETAILS_REPORTED_FAILURE = 'REQUEST_DETAILS_REPORTED_FAILURE'

export const REQUEST_UPDATE_DETAILS_REPORTED = 'REQUEST_UPDATE_DETAILS_REPORTED'
export const REQUEST_UPDATE_DETAILS_REPORTED_SUCCESS = 'REQUEST_UPDATE_DETAILS_REPORTED_SUCCESS'
export const REQUEST_UPDATE_DETAILS_REPORTED_FAILURE = 'REQUEST_UPDATE_DETAILS_REPORTED_FAILURE'

export const UPDATE_REPORTED_ACTION = 'UPDATE_REPORTED_ACTION'

const defaultState = {
  detailReported: null,
  fetchingDetailsReported: true,
  fetchingDetailsReportedFailed: false,

  updateDetailReported: [],
  fetchingUpdateDetailsReported: true,
  failedFetchingUpdateDetailsReported: false,
}

const reducer = createReducer(defaultState, {
  [REQUEST_DETAILS_REPORTED]: (state) => {
    return {
      ...state,
      fetchingDetailsReported: true,
      fetchingDetailsReportedFailed: false,
    }
  },
  [REQUEST_DETAILS_REPORTED_SUCCESS]: (state, action) => {
    return {
      ...state,
      detailReported: action.payload.data,
      fetchingDetailsReported: false,
      fetchingDetailsReportedFailed: false,
    }
  },
  [REQUEST_DETAILS_REPORTED_FAILURE]: (state) => {
    return {
      ...state,
      fetchingDetailsReported: false,
      fetchingDetailsReportedFailed: true,
    }
  },

  [REQUEST_UPDATE_DETAILS_REPORTED]: (state) => {
    return {
      ...state,
      fetchingUpdateDetailsReported: true,
      failedFetchingUpdateDetailsReported: false,
    }
  },
  [REQUEST_UPDATE_DETAILS_REPORTED_SUCCESS]: (state, action) => {
    return {
      ...state,
      updateDetailReported: action.payload,
      fetchingUpdateDetailsReported: false,
      failedFetchingUpdateDetailsReported: false,
    }
  },
  [REQUEST_UPDATE_DETAILS_REPORTED_FAILURE]: (state) => {
    return {
      ...state,
      fetchingUpdateDetailsReported: false,
      failedFetchingUpdateDetailsReported: true,
    }
  },
})

export default reducer
export const namespace = 'detailReported'

export const updateReportedAction = (credentials) => ({
  type: UPDATE_REPORTED_ACTION,
  payload: credentials,
})

export const fetchDetailsReported = (id) => async (dispatch, getState) => {
  return await callAPI({
    method: 'get',
    apiPath: `/report-listing/${id}`,
    actionTypes: [
      REQUEST_DETAILS_REPORTED,
      REQUEST_DETAILS_REPORTED_SUCCESS,
      REQUEST_DETAILS_REPORTED_FAILURE,
    ],
    dispatch,
    getState,
  })
}

export const handleUpdateDetailsReported =
  ({ id, payload }) =>
  async (dispatch, getState) => {
    return await callAPI({
      method: 'put',
      apiPath: `/report-listing/${id}`,
      actionTypes: [
        REQUEST_UPDATE_DETAILS_REPORTED,
        REQUEST_UPDATE_DETAILS_REPORTED_SUCCESS,
        REQUEST_UPDATE_DETAILS_REPORTED_FAILURE,
      ],
      variables: { ...payload },
      dispatch,
      getState,
    })
  }
