import { createReducer } from 'redux-create-reducer'
import callAPI from 'utils/callAPI'
import {
  REQUEST_USER_MANAGEMENT,
  REQUEST_USER_MANAGEMENT_FAILURE,
  REQUEST_USER_MANAGEMENT_SUCCESS,
} from '../userManagement'

export const REQUEST_DETAILS_LISTING = 'REQUEST_DETAILS_LISTING'
export const REQUEST_DETAILS_LISTING_SUCCESS = 'REQUEST_DETAILS_LISTING_SUCCESS'
export const REQUEST_DETAILS_LISTING_FAILURE = 'REQUEST_DETAILS_LISTING_FAILURE'

export const REQUEST_UPDATE_DETAILS_LISTING = 'REQUEST_UPDATE_DETAILS_LISTING'
export const REQUEST_UPDATE_DETAILS_LISTING_SUCCESS = 'REQUEST_UPDATE_DETAILS_LISTING_SUCCESS'
export const REQUEST_UPDATE_DETAILS_LISTING_FAILURE = 'REQUEST_UPDATE_DETAILS_LISTING_FAILURE'

export const UPDATE_AGENT_ACTION = 'UPDATE_AGENT_ACTION'
export const HANDLE_CLEAR_USER_ACTION = 'HANDLE_CLEAR_USER_ACTION'

export const CONFIRM_HOUSE_SOLD_ACTION = 'CONFIRM_HOUSE_SOLD_ACTION'
export const REQUEST_CONFIRM_HOUSE_SOLD = 'REQUEST_CONFIRM_HOUSE_SOLD'
export const REQUEST_CONFIRM_HOUSE_SOLD_SUCCESS = 'REQUEST_CONFIRM_HOUSE_SOLD_SUCCESS'
export const REQUEST_CONFIRM_HOUSE_SOLD_FAILURE = 'REQUEST_CONFIRM_HOUSE_SOLD_FAILURE'

const defaultState = {
  data: [],
  fetchingDetailsListing: true,
  failedFetchingDetailsListing: false,

  users: [],
  usersMetaData: {},
  fetchingUserManagement: true,
  failedFetchingUserManagement: false,

  fetchingUpdateDetailsListing: true,
  failedFetchingUpdateDetailsListing: false,

  fetchingConfirmHouseSold: false,
  failedFetchingConfirmHouseSold: false,
}

const reducer = createReducer(defaultState, {
  [REQUEST_DETAILS_LISTING]: (state) => {
    return {
      ...state,
      fetchingDetailsListing: true,
      failedFetchingDetailsListing: false,
    }
  },
  [REQUEST_DETAILS_LISTING_SUCCESS]: (state, action) => {
    return {
      ...state,
      data: action.payload,
      fetchingDetailsListing: false,
      failedFetchingDetailsListing: false,
    }
  },
  [REQUEST_DETAILS_LISTING_FAILURE]: (state) => {
    return {
      ...state,
      fetchingDetailsListing: false,
      failedFetchingDetailsListing: true,
    }
  },

  [REQUEST_UPDATE_DETAILS_LISTING]: (state) => {
    return {
      ...state,
      fetchingUpdateDetailsListing: true,
      failedFetchingUpdateDetailsListing: false,
    }
  },
  [REQUEST_UPDATE_DETAILS_LISTING_SUCCESS]: (state, action) => {
    return {
      ...state,
      data: action.payload,
      fetchingUpdateDetailsListing: false,
      failedFetchingUpdateDetailsListing: false,
    }
  },
  [REQUEST_UPDATE_DETAILS_LISTING_FAILURE]: (state) => {
    return {
      ...state,
      fetchingUpdateDetailsListing: false,
      failedFetchingUpdateDetailsListing: true,
    }
  },
  [REQUEST_USER_MANAGEMENT]: (state) => {
    return {
      ...state,
      fetchingUserManagement: true,
      failedFetchingUserManagement: false,
    }
  },
  [REQUEST_USER_MANAGEMENT_SUCCESS]: (state, action) => {
    return {
      ...state,
      usersMetadata: action.payload.metadata,
      users: state.users.length
        ? state.users.concat(action.payload.data).reduce((unique, o) => {
            if (!unique.some((obj) => obj._id === o._id)) {
              unique.push(o)
            }
            return unique
          }, [])
        : action.payload.data,
      fetchingUserManagement: false,
      failedFetchingUserManagement: false,
    }
  },
  [REQUEST_USER_MANAGEMENT_FAILURE]: (state) => {
    return {
      ...state,
      fetchingUserManagement: false,
      failedFetchingUserManagement: true,
    }
  },
  [HANDLE_CLEAR_USER_ACTION]: (state) => {
    return {
      ...state,
      users: [],
      usersMetaData: {},
      fetchingUserManagement: true,
      failedFetchingUserManagement: false,
    }
  },

  [REQUEST_CONFIRM_HOUSE_SOLD]: (state) => {
    return {
      ...state,
      fetchingConfirmHouseSold: true,
      failedFetchingConfirmHouseSold: false,
    }
  },
  [REQUEST_CONFIRM_HOUSE_SOLD_SUCCESS]: (state, action) => {
    return {
      ...state,
      fetchingConfirmHouseSold: false,
      failedFetchingConfirmHouseSold: false,
    }
  },
  [REQUEST_CONFIRM_HOUSE_SOLD_FAILURE]: (state) => {
    return {
      ...state,
      fetchingConfirmHouseSold: false,
      failedFetchingConfirmHouseSold: true,
    }
  },
})

export default reducer
export const namespace = 'detailListing'

export const updateAgentAction = (credentials) => ({
  type: UPDATE_AGENT_ACTION,
  payload: credentials,
})

export const clearUser = () => ({
  type: HANDLE_CLEAR_USER_ACTION,
})

export const fetchDetailsListing = (id) => async (dispatch, getState) => {
  return await callAPI({
    method: 'get',
    apiPath: `/listing/${id}`,
    actionTypes: [
      REQUEST_DETAILS_LISTING,
      REQUEST_DETAILS_LISTING_SUCCESS,
      REQUEST_DETAILS_LISTING_FAILURE,
    ],
    dispatch,
    getState,
  })
}

export const handleUpdateDetailsListing =
  ({ id, payload }) =>
  async (dispatch, getState) => {
    return await callAPI({
      method: 'put',
      apiPath: `/listing/${id}`,
      actionTypes: [
        REQUEST_UPDATE_DETAILS_LISTING,
        REQUEST_UPDATE_DETAILS_LISTING_SUCCESS,
        REQUEST_UPDATE_DETAILS_LISTING_FAILURE,
      ],
      variables: { ...payload },
      dispatch,
      getState,
    })
  }

export const confirmHouseSoldAction = (payload) => ({
  type: CONFIRM_HOUSE_SOLD_ACTION,
  payload,
})

export const handleConfirmHouseSold = (payload) => async (dispatch, getState) => {
  return await callAPI({
    method: 'post',
    apiPath: '/listing/confirm-house-sold',
    actionTypes: [
      REQUEST_CONFIRM_HOUSE_SOLD,
      REQUEST_CONFIRM_HOUSE_SOLD_SUCCESS,
      REQUEST_CONFIRM_HOUSE_SOLD_FAILURE,
    ],
    variables: { ...payload },
    dispatch,
    getState,
  })
}
