import { all, fork, put, takeLatest } from 'redux-saga/effects'
import { REPORTED_LIST_SEARCH, fetchReportedList } from './index'
import { PAGINATION_DEFAULT_LIMIT, PAGINATION_DEFAULT_PAGE, SORT_DEFAULT } from 'utils/constants'

function* initPage() {
  yield put(
    fetchReportedList({
      limit: PAGINATION_DEFAULT_LIMIT,
      page: PAGINATION_DEFAULT_PAGE,
      sortCreatedAt: SORT_DEFAULT,
    }),
  )
}

function* listenEvents() {
  yield takeLatest(REPORTED_LIST_SEARCH, function* (action) {
    yield put(fetchReportedList(action.payload))
  })
}

export default function* loadReportedListing() {
  yield all([fork(initPage), fork(listenEvents)])
}
