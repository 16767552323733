import { createReducer } from 'redux-create-reducer'
import OneSignal from 'react-onesignal'
import { SHOW_BELL_NOTIFICATION } from '../app'
export const FETCH_NOTIFICATION = 'FETCH_NOTIFICATION'
export const FETCH_NOTIFICATION_ACTION = 'FETCH_NOTIFICATION_ACTION'
export const FETCH_NOTIFICATION_SUCCESS = 'FETCH_NOTIFICATION_SUCCESS'
export const FETCH_NOTIFICATION_FAILURE = 'FETCH_NOTIFICATION_FAILURE'

export const CONNECT_ONE_SIGNAL = 'CONNECT_ONE_SIGNAL'
export const CONNECT_ONE_SIGNAL_ACTION = 'CONNECT_ONE_SIGNAL_ACTION'
export const CONNECT_ONE_SIGNAL_SUCCESS = 'CONNECT_ONE_SIGNAL_SUCCESS'
export const CONNECT_ONE_SIGNAL_FAILURE = 'CONNECT_ONE_SIGNAL_FAILURE'

const defaultState = {
  fetchingNotification: false,
  failedFetchingNotification: false,
}

const reducer = createReducer(defaultState, {
  [FETCH_NOTIFICATION]: (state, action) => {
    return {
      ...state,
      error: action.payload,
      fetchingNotification: true,
      failedFetchingNotification: false,
    }
  },
  [FETCH_NOTIFICATION_SUCCESS]: (state, action) => {
    return {
      ...state,
      error: action.payload,
      fetchingNotification: false,
      failedFetchingNotification: false,
    }
  },
  [FETCH_NOTIFICATION_FAILURE]: (state, action) => {
    return {
      ...state,
      error: action.payload,
      fetchingNotification: false,
      failedFetchingNotification: true,
    }
  },
})

export default reducer
export const namespace = 'oneSignal'

export const fetchNotificationAction = (payload) => ({
  type: FETCH_NOTIFICATION_ACTION,
  payload: payload,
})

export const connectOneSignal = (id) => async (dispatch, getState) => {
  await OneSignal.init({
    appId: process.env.REACT_APP_ONESIGNAL_ID,
  }).then(() => {
    OneSignal.showSlidedownPrompt()
  })

  await OneSignal.sendTag('type', 'admin')
  await OneSignal.on('notificationDisplay', function () {
    dispatch({ type: FETCH_NOTIFICATION_SUCCESS, meta: {}, payload: {} })
    dispatch({ type: SHOW_BELL_NOTIFICATION })
  })
}
