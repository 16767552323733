import { all, fork, put, select, takeLatest } from 'redux-saga/effects'
import {
  VENDOR_DETAILS_UPDATE_ACTION,
  VENDOR_DETAILS_UPLOAD_PHOTO_ACTION,
  fetchVendorDetailsUploadPhoto,
  fetchVendorDetails,
  fetchVendorDetailsUpdate,
  fetchVendorReviews,
  fetchVendorDetailsChangeStatus,
  VENDOR_DETAILS_UPDATE_REQUEST_SUCCESS,
  VENDOR_DETAILS_UPDATE_REQUEST_FAILURE,
  VENDOR_DETAILS_REQUEST_FAILURE,
  VENDOR_DETAILS_CHANGE_STATUS_ACTION,
  VENDOR_DETAILS_CHANGE_STATUS_REQUEST_SUCCESS,
  VENDOR_DETAILS_CHANGE_FILTER_ACTION,
} from '.'
import { ROUTE_VENDOR_MANAGEMENT } from '../routing'
import { redirect } from 'redux-first-router'
import { showToastAlert } from '../app'
import { PAGINATION_DEFAULT_LIMIT, PAGINATION_DEFAULT_PAGE } from 'utils/constants'

function* initPage() {
  const { location } = yield select()
  const { payload } = location
  const { id } = payload
  yield put(fetchVendorDetails(id))
  yield put(
    fetchVendorReviews({
      limit: PAGINATION_DEFAULT_LIMIT,
      page: PAGINATION_DEFAULT_PAGE,
      vendorCateId: id,
    }),
  )
}

function* listenEvents() {
  yield takeLatest(VENDOR_DETAILS_UPDATE_ACTION, function* (action) {
    yield put(fetchVendorDetailsUpdate(action.payload))
  })

  yield takeLatest(VENDOR_DETAILS_UPDATE_REQUEST_SUCCESS, function* (action) {
    yield put(redirect({ type: ROUTE_VENDOR_MANAGEMENT }))
    yield put(showToastAlert(action.payload.message))
  })

  yield takeLatest(
    [VENDOR_DETAILS_REQUEST_FAILURE, VENDOR_DETAILS_UPDATE_REQUEST_FAILURE],
    function* (action) {
      yield put(showToastAlert(action.payload.data.message, 'error'))
    },
  )

  yield takeLatest(VENDOR_DETAILS_UPLOAD_PHOTO_ACTION, function* (action) {
    const { payload } = action
    yield put(fetchVendorDetailsUploadPhoto(payload.file))
  })

  yield takeLatest(VENDOR_DETAILS_CHANGE_STATUS_ACTION, function* (action) {
    yield put(fetchVendorDetailsChangeStatus(action.payload))
  })

  yield takeLatest(VENDOR_DETAILS_CHANGE_STATUS_REQUEST_SUCCESS, function* (action) {
    yield put(showToastAlert(action.payload.message))
  })

  yield takeLatest(VENDOR_DETAILS_CHANGE_FILTER_ACTION, function* (action) {
    yield put(fetchVendorReviews(action.payload))
  })
}

export default function* loadVendorManagementUpdate() {
  yield all([fork(initPage), fork(listenEvents)])
}
