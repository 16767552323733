import { createReducer } from 'redux-create-reducer'
import callAPI from 'utils/callAPI'

export const REPORTED_LIST_REQUEST = 'REPORTED_LIST_REQUEST'
export const REPORTED_LIST_REQUEST_SUCCESS = 'REPORTED_LIST_REQUEST_SUCCESS'
export const REPORTED_LIST_REQUEST_FAILURE = 'REPORTED_LIST_REQUEST_FAILURE'
export const REPORTED_LIST_SEARCH = 'REPORTED_LIST_SEARCH'

const defaultState = {
  reportedList: [],
  fetchingList: true,
  fetchingListFailed: false,
}

const reducer = createReducer(defaultState, {
  [REPORTED_LIST_REQUEST]: (state) => {
    return {
      ...state,
      fetchingList: true,
      fetchingListFailed: false,
    }
  },
  [REPORTED_LIST_REQUEST_SUCCESS]: (state, action) => {
    return {
      ...state,
      reportedList: action.payload,
      fetchingList: false,
      fetchingListFailed: false,
    }
  },
  [REPORTED_LIST_REQUEST_FAILURE]: (state) => {
    return {
      ...state,
      fetchingList: false,
      fetchingListFailed: true,
    }
  },
})

export default reducer
export const namespace = 'reportedListing'

export const searchAction = (filters) => ({
  type: REPORTED_LIST_SEARCH,
  payload: filters,
})

export const fetchReportedList = (filters) => async (dispatch, getState) => {
  return await callAPI({
    method: 'get',
    apiPath: '/report-listing',
    actionTypes: [
      REPORTED_LIST_REQUEST,
      REPORTED_LIST_REQUEST_SUCCESS,
      REPORTED_LIST_REQUEST_FAILURE,
    ],
    variables: { ...filters },
    dispatch,
    getState,
  })
}
