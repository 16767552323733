import { all, fork, put, takeLatest } from 'redux-saga/effects'
import {
  REQUEST_LISTING_MANAGEMENT_FAILURE,
  SEARCH_ACTION,
  fetchListingManagement,
  fetchListingPropertyType,
} from './index'
import { PAGINATION_DEFAULT_LIMIT, PAGINATION_DEFAULT_PAGE } from 'utils/constants'
import { showToastAlert } from '../app'

function* initPage() {
  yield put(
    fetchListingManagement({
      limit: PAGINATION_DEFAULT_LIMIT,
      page: PAGINATION_DEFAULT_PAGE,
    }),
  )
  yield put(fetchListingPropertyType())
}

function* listenEvents() {
  yield takeLatest(SEARCH_ACTION, function* (action) {
    yield put(fetchListingManagement(action.payload))
  })
  yield takeLatest(REQUEST_LISTING_MANAGEMENT_FAILURE, function* (action) {
    yield put(showToastAlert(action.payload.data.message, 'error'))
  })
}

export default function* loadListingManagement() {
  yield all([fork(initPage), fork(listenEvents)])
}
