import { all, fork, put, select, takeLatest } from 'redux-saga/effects'
import {
  CONFIRM_UPDATE_LESSON_ACTION,
  fetchCreateCourse,
  handleFetchListLesson,
  handleUpdateLesson,
  handleUploadCreateFile,
  REQUEST_CREATE_LESSON_FAILURED,
  REQUEST_DELETE_LESSON_FAILURED,
  REQUEST_UPDATE_LESSON_FAILURED,
  REQUEST_UPDATE_LESSON_SUCCESS,
  UPLOAD_PHOTO_CREATE_COURSE_ACTION,
  UPLOAD_PHOTO_CREATE_LESSON_ACTION,
  fetchCreateQuiz,
  fetchMultiQuestion,
  resetStateAction,
  CREATE_COURSE_ACTION,
  CREATE_COURSE_REQUEST_SUCCESS,
  CREATE_QUIZ_REQUEST_SUCCESS,
  fetchMultiLessonCreate,
  fetchLessonCreate,
  CONFIRM_UPLOAD_PDF_AND_CREATE_LESSON,
  REQUEST_UPLOAD_EBOOK_THUMBNAIL_SUCCESS,
  REQUEST_UPLOAD_EBOOK_SUCCESS,
  THUMBNAIL_SELECTED,
  ebookUploadFile,
  updateCurrentEBook,
  EBOOK_SELECTED,
} from '.'
import { showToastAlert } from '../app'
import { redirect } from 'redux-first-router'
import { ROUTE_COURSE_MANAGEMENT } from '../routing'
import { REQUEST_DELETE_LESSON_SUCCESS } from '../courseManagementUpdate'
import { createCourseMessage } from 'utils/validation'
import { CourseType } from 'utils/enum'

function* initPage() {
  yield put(resetStateAction())
}

function* listenEvents() {
  yield takeLatest(CREATE_COURSE_ACTION, function* (action) {
    const courseData = action.payload
    yield put(
      fetchCreateCourse({
        ...courseData.generalInformation,
        page: courseData?.ebook?.page,
        video: courseData?.videos?.length,
        courseInfo: courseData,
      }),
    )
  })

  yield takeLatest(CREATE_COURSE_REQUEST_SUCCESS, function* (action) {
    const { courseInfo } = action.meta.variables
    const courseId = action.payload.data?._id
    const { quizzes, videos, ebook, generalInformation } = courseInfo
    const { type } = generalInformation
    // create lesson
    if (type === CourseType.VIDEO) {
      yield put(fetchMultiLessonCreate({ courseId, lessons: videos }))
    } else {
      yield put(fetchLessonCreate({ courseId, ...ebook }))
    }
    // create quiz
    for (let i = 0; i < quizzes.length; i++) {
      const { questions } = quizzes[i]
      yield put(
        fetchCreateQuiz({
          courseId,
          questions,
          location: quizzes[i].location,
          theLast: i === quizzes.length - 1,
        }),
      )
    }
  })

  yield takeLatest([CREATE_QUIZ_REQUEST_SUCCESS], function* (action) {
    const { questions } = action.meta.variables
    const quizId = action.payload.data._id
    yield put(fetchMultiQuestion({ quizId, questions }))

    if (action.meta?.variables?.theLast) {
      yield put(redirect({ type: ROUTE_COURSE_MANAGEMENT }))
      yield put(showToastAlert(createCourseMessage))
    }
  })

  yield takeLatest(CONFIRM_UPDATE_LESSON_ACTION, function* (action) {
    yield put(handleUpdateLesson(action.payload))
  })

  yield takeLatest(REQUEST_UPDATE_LESSON_SUCCESS, function* (action) {
    const { courseManagementCreate } = yield select()
    yield put(showToastAlert(action.payload.message))
    yield put(
      handleFetchListLesson({
        limit: 1,
        page: 100,
        courseId: courseManagementCreate?.courseData?.courseId,
      }),
    )
  })

  yield takeLatest(REQUEST_UPDATE_LESSON_FAILURED, function* (action) {
    yield put(showToastAlert(action.payload.data.message, 'error'))
  })

  yield takeLatest(REQUEST_CREATE_LESSON_FAILURED, function* (action) {
    yield put(showToastAlert(action.payload.data.message, 'error'))
  })

  yield takeLatest(REQUEST_DELETE_LESSON_SUCCESS, function* (action) {
    const { courseManagementCreate } = yield select()
    yield put(showToastAlert(action.payload.message))
    yield put(
      handleFetchListLesson({
        limit: 1,
        page: 100,
        courseId: courseManagementCreate?.courseData?.courseId,
      }),
    )
  })

  yield takeLatest(REQUEST_DELETE_LESSON_FAILURED, function* (action) {
    yield put(showToastAlert(action.payload.data.message, 'error'))
  })

  yield takeLatest(UPLOAD_PHOTO_CREATE_LESSON_ACTION, function* (action) {
    yield put(handleUploadCreateFile({ action: action.payload.file, type: 'lesson' }))
  })

  yield takeLatest(UPLOAD_PHOTO_CREATE_COURSE_ACTION, function* (action) {
    yield put(handleUploadCreateFile({ action: action.payload.file, type: 'course' }))
  })

  yield takeLatest(CONFIRM_UPLOAD_PDF_AND_CREATE_LESSON, function* (action) {
    yield put(handleUploadCreateFile({ action: action.payload, type: 'lesson' }))
  })
}

function* ebookEvents() {
  yield takeLatest([THUMBNAIL_SELECTED, EBOOK_SELECTED], function* (action) {
    const file = action.payload
    yield put(
      ebookUploadFile({
        file,
        metadata: { type: action.type === THUMBNAIL_SELECTED ? 'thumbnail' : 'ebook' },
      }),
    )
  })

  yield takeLatest(REQUEST_UPLOAD_EBOOK_THUMBNAIL_SUCCESS, function* (action) {
    const { courseManagementCreate } = yield select()
    yield put(
      updateCurrentEBook({
        ...courseManagementCreate.currentEbook,
        thumbnail: action.payload.data[0]?.link,
        isChanged: true,
      }),
    )
  })

  yield takeLatest(REQUEST_UPLOAD_EBOOK_SUCCESS, function* (action) {
    const { courseManagementCreate } = yield select()
    yield put(
      updateCurrentEBook({
        ...courseManagementCreate.currentEbook,
        link: action.payload.data[0]?.link,
        isChanged: true,
      }),
    )
  })
}

export default function* loadCourseManagementCreate() {
  yield all([fork(initPage), fork(listenEvents), fork(ebookEvents)])
}
