import { fork } from 'redux-saga/effects'

function* initPage() {
  //TODO connect socket
}

//root handle
export default function* oneSignalSaga() {
  yield fork(initPage)
}
