import { all, fork, put, select, takeLatest } from 'redux-saga/effects'
import {
  fetchDetailsReported,
  handleUpdateDetailsReported,
  REQUEST_UPDATE_DETAILS_REPORTED_SUCCESS,
  UPDATE_REPORTED_ACTION,
} from '.'
import { showToastAlert } from '../app'
import { ROUTE_REPORTED_LISTING } from '../routing'
import { redirect } from 'redux-first-router'

function* initPage() {
  const { location } = yield select()
  const { payload } = location
  const { id } = payload
  yield put(fetchDetailsReported(id))
}

export function* listEvents() {
  yield takeLatest(UPDATE_REPORTED_ACTION, function* (action) {
    const { payload } = action
    const { location } = yield select()

    yield put(handleUpdateDetailsReported({ id: location.payload.id, payload: payload }))
  })
  yield takeLatest(REQUEST_UPDATE_DETAILS_REPORTED_SUCCESS, function* (action) {
    yield put(redirect({ type: ROUTE_REPORTED_LISTING }))
    yield put(showToastAlert(action.payload.message))
  })
}

export default function* loadDetailReported() {
  yield all([fork(initPage), fork(listEvents)])
}
