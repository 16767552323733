import { all, fork, put, takeLatest } from 'redux-saga/effects'
import {
  fetchNotification,
  FETCH_NOTIFICATION_LIST_ACTION,
  REQUEST_READ_NOTIFICATION_ACTION,
  updateReadNotification,
} from './index'

function* initPage() {}

function* listenEvents() {
  yield takeLatest(FETCH_NOTIFICATION_LIST_ACTION, function* (action) {
    yield put(fetchNotification(action.payload))
  })
}

function* updateNotificationRead() {
  yield takeLatest(REQUEST_READ_NOTIFICATION_ACTION, function* (action) {
    yield put(updateReadNotification({ id: action.payload.id }))
    yield put(fetchNotification({ page: 1, limit: 10 }))
  })
}

export default function* loadNotificationList() {
  yield all([fork(initPage), fork(listenEvents), fork(updateNotificationRead)])
}
