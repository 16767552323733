import { all, fork, put, takeLatest } from 'redux-saga/effects'
import {
  COURSE_MANAGEMENT_DELETE_ACTION,
  COURSE_MANAGEMENT_DELETE_FAILURE,
  COURSE_MANAGEMENT_DELETE_SUCCESS,
  COURSE_MANAGEMENT_SEARCH_ACTION,
  fetchCourseManagement,
  fetchDeleteCourseManagement,
} from './index'
import { showToastAlert } from '../app'
import { PAGINATION_DEFAULT_LIMIT, PAGINATION_DEFAULT_PAGE, SORT_DEFAULT } from 'utils/constants'

function* initPage() {
  yield put(
    fetchCourseManagement({
      limit: PAGINATION_DEFAULT_LIMIT,
      page: PAGINATION_DEFAULT_PAGE,
      sortCreatedAt: SORT_DEFAULT,
    }),
  )
}

function* listenEvents() {
  yield takeLatest(COURSE_MANAGEMENT_SEARCH_ACTION, function* (action) {
    yield put(fetchCourseManagement(action.payload))
  })

  yield takeLatest(COURSE_MANAGEMENT_DELETE_ACTION, function* (action) {
    yield put(fetchDeleteCourseManagement(action.payload))
  })

  yield takeLatest(COURSE_MANAGEMENT_DELETE_SUCCESS, function* (action) {
    yield put(showToastAlert(action.payload.message))
    yield put(
      fetchCourseManagement({
        limit: PAGINATION_DEFAULT_LIMIT,
        page: PAGINATION_DEFAULT_PAGE,
        sortCreatedAt: SORT_DEFAULT,
      }),
    )
  })
  yield takeLatest(COURSE_MANAGEMENT_DELETE_FAILURE, function* (action) {
    yield put(showToastAlert(action.payload.message))
  })
}

export default function* loadCourseManagement() {
  yield all([fork(initPage), fork(listenEvents)])
}
