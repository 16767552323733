import { createReducer } from 'redux-create-reducer'
import callAPI from 'utils/callAPI'

export const VENDOR_MANAGEMENT_REQUEST_LIST = 'VENDOR_MANAGEMENT_REQUEST_LIST'
export const VENDOR_MANAGEMENT_REQUEST_LIST_SUCCESS = 'VENDOR_MANAGEMENT_REQUEST_LIST_SUCCESS'
export const VENDOR_MANAGEMENT_REQUEST_LIST_FAILURE = 'VENDOR_MANAGEMENT_REQUEST_LIST_FAILURE'

export const VENDOR_MANAGEMENT_CHANGE_FILTER = 'VENDOR_MANAGEMENT_CHANGE_FILTER'
export const VENDOR_MANAGEMENT_SEARCH_ACTION = 'VENDOR_MANAGEMENT_SEARCH_ACTION'

const defaultState = {
  vendorInfo: {},
  fetchingList: true,
  fetchingListFailed: false,
}

const reducer = createReducer(defaultState, {
  [VENDOR_MANAGEMENT_REQUEST_LIST]: (state) => {
    return {
      ...state,
      fetchingList: true,
      fetchingListFailed: false,
    }
  },
  [VENDOR_MANAGEMENT_REQUEST_LIST_SUCCESS]: (state, action) => {
    return {
      ...state,
      vendorInfo: action.payload,
      fetchingList: false,
      fetchingListFailed: false,
    }
  },
  [VENDOR_MANAGEMENT_REQUEST_LIST_FAILURE]: (state) => {
    return {
      ...state,
      fetchingList: false,
      fetchingListFailed: true,
    }
  },
})

export default reducer
export const namespace = 'vendorManagement'

export const searchVendorAction = (filters) => ({
  type: VENDOR_MANAGEMENT_SEARCH_ACTION,
  payload: filters,
})

export const fetchVendorManagement = (filters) => async (dispatch, getState) => {
  return await callAPI({
    method: 'get',
    apiPath: '/vendor-category',
    actionTypes: [
      VENDOR_MANAGEMENT_REQUEST_LIST,
      VENDOR_MANAGEMENT_REQUEST_LIST_SUCCESS,
      VENDOR_MANAGEMENT_REQUEST_LIST_FAILURE,
    ],
    variables: { ...filters },
    dispatch,
    getState,
  })
}
