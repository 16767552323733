import RootRoute from '../../RootRoute'
// todo: add global stuff -- snackbar/modal targets, intercom button, ...
// import 'antd/dist/antd.css';
import 'react-dropzone-uploader/dist/styles.css'

const App = () => {
  return <RootRoute />
}

export default App
