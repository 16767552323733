import { createReducer } from 'redux-create-reducer'
import callAPI from 'utils/callAPI'

export const REQUEST_FETCH_USER_REGISTRATION_DETAILS = 'REQUEST_FETCH_USER_REGISTRATION_DETAILS'
export const REQUEST_FETCH_USER_REGISTRATION_DETAILS_SUCCESS =
  'REQUEST_FETCH_USER_REGISTRATION_DETAILS_SUCCESS'
export const REQUEST_FETCH_USER_REGISTRATION_DETAILS_FAILURED =
  'REQUEST_FETCH_USER_REGISTRATION_DETAILS_FAILURED'

export const REQUEST_UPDATE_USER_REGISTRATION = 'REQUEST_UPDATE_USER_REGISTRATION'
export const REQUEST_UPDATE_USER_REGISTRATION_SUCCESS = 'REQUEST_UPDATE_USER_REGISTRATION_SUCCESS'
export const REQUEST_UPDATE_USER_REGISTRATION_FAILURED = 'REQUEST_UPDATE_USER_REGISTRATION_FAILURED'

export const REQUEST_UPDATE_USER_REGISTRATION_AGENT = 'REQUEST_UPDATE_USER_REGISTRATION_AGENT'
export const REQUEST_UPDATE_USER_REGISTRATION_AGENT_SUCCESS =
  'REQUEST_UPDATE_USER_REGISTRATION_AGENT_SUCCESS'
export const REQUEST_UPDATE_USER_REGISTRATION_AGENT_FAILURED =
  'REQUEST_UPDATE_USER_REGISTRATION_AGENT_FAILURED'

export const REQUEST_UPDATE_USER_REGISTRATION_VENDOR = 'REQUEST_UPDATE_USER_REGISTRATION_VENDOR'
export const REQUEST_UPDATE_USER_REGISTRATION_VENDOR_SUCCESS =
  'REQUEST_UPDATE_USER_REGISTRATION_VENDOR_SUCCESS'
export const REQUEST_UPDATE_USER_REGISTRATION_VENDOR_FAILURED =
  'REQUEST_UPDATE_USER_REGISTRATION_VENDOR_FAILURED'

export const REQUEST_ALL_VENDOR_CATEGORY_UPDATE = 'REQUEST_ALL_VENDOR_CATEGORY_UPDATE'
export const REQUEST_ALL_VENDOR_CATEGORY_UPDATE_SUCCESS =
  'REQUEST_ALL_VENDOR_CATEGORY_UPDATE_SUCCESS'
export const REQUEST_ALL_VENDOR_CATEGORY_UPDATE_FAILURE =
  'REQUEST_ALL_VENDOR_CATEGORY_UPDATE_FAILURE'

export const CONFIRM_UPDATE_USER_ACTION = 'CONFIRM_UPDATE_USER_ACTION'
export const FETCH_USER_DETAIL_ACTION = 'TRIGGER_FETCH_USER_DETAIL_ACTION'
export const GET_ALL_VENDOR_CATEGORY_UPDATE_ACTION = 'GET_ALL_VENDOR_CATEGORY_UPDATE_ACTION'

export const UPLOAD_FILE_DRE_LICENSE_ACTION = 'UPLOAD_FILE_DRE_LICENSE'
export const UPLOAD_FILE_DRIVER_LICENSE_ACTION = 'UPLOAD_FILE_DRIVER_LICENSE'

export const REQUEST_UPLOAD_FILE_DRE_LICENSE = 'REQUEST_UPLOAD_FILE_DRE_LICENSE'
export const REQUEST_UPLOAD_FILE_DRE_LICENSE_SUCCESS = 'REQUEST_UPLOAD_FILE_DRE_LICENSE_SUCCESS'
export const REQUEST_UPLOAD_FILE_DRE_LICENSE_FAILURED = 'REQUEST_UPLOAD_FILE_DRE_LICENSE_FAILURED'

export const REQUEST_UPLOAD_FILE_DRIVER_LICENSE = 'REQUEST_UPLOAD_FILE_DRIVER_LICENSE'
export const REQUEST_UPLOAD_FILE_DRIVER_LICENSE_SUCCESS =
  'REQUEST_UPLOAD_FILE_DRIVER_LICENSE_SUCCESS'
export const REQUEST_UPLOAD_FILE_DRIVER_LICENSE_FAILURED =
  'REQUEST_UPLOAD_FILE_DRIVER_LICENSE_FAILURED'

export const REQUEST_UPLOAD_FILE_UPDATE_USER = 'REQUEST_UPLOAD_FILE_UPDATE_USER'
export const REQUEST_UPLOAD_FILE_UPDATE_USER_SUCCESS = 'REQUEST_UPLOAD_FILE_UPDATE_USER_SUCCESS'
export const REQUEST_UPLOAD_FILE_UPDATE_USER_FAILURED = 'REQUEST_UPLOAD_FILE_UPDATE_USER_FAILURED'

export const UPLOAD_PHOTO_UPDATE_USER_ACTION = 'UPLOAD_PHOTO'

const defaultState = {
  fetchingUpdateUser: false,
  failedFetchingUpdateUser: false,

  fetchingUpdateUserAgent: false,
  failedFetchingUpdateUserAgent: false,

  fetchingUpdateUserVendor: false,
  failedFetchingUpdateUserVendor: false,

  photo: undefined,
  fetchingUploadPhoto: false,
  failedFetchingUploadPhoto: false,

  userDetails: {},
  fetchingFetchUserDetails: false,
  failedFetchingFetchUserDetails: false,

  listAllVendorCategoryUpdate: [],
  fetchingListAllVendorCategoryUpdate: false,
  fetchingListAllVendorCategoryUpdateFailed: false,

  dreLicenseFile: undefined,
  fetchingUploadDreLicenseFile: false,
  failedFetchingUploadDreLicenseFile: false,

  driverLicenseFile: undefined,
  fetchingUploadDriverLicense: false,
  failedFetchingUploadDriverLicense: false,
}

const reducer = createReducer(defaultState, {
  [REQUEST_UPDATE_USER_REGISTRATION]: (state, action) => {
    return {
      ...state,
      fetchingUpdateUser: true,
      failedFetchingUpdateUser: false,
    }
  },
  [REQUEST_UPDATE_USER_REGISTRATION_SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      fetchingUpdateUser: false,
      failedFetchingUpdateUser: false,
    }
  },
  [REQUEST_UPDATE_USER_REGISTRATION_FAILURED]: (state, action) => {
    return {
      ...state,
      error: action.payload,
      fetchingUpdateUser: false,
      failedFetchingUpdateUser: true,
    }
  },

  [REQUEST_UPDATE_USER_REGISTRATION_VENDOR]: (state, action) => {
    return {
      ...state,
      fetchingUpdateUserVendor: true,
      failedFetchingUpdateUserVendor: false,
    }
  },
  [REQUEST_UPDATE_USER_REGISTRATION_VENDOR_SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      fetchingUpdateUserVendor: false,
      failedFetchingUpdateUserVendor: false,
    }
  },
  [REQUEST_UPDATE_USER_REGISTRATION_VENDOR_FAILURED]: (state, action) => {
    return {
      ...state,
      error: action.payload,
      fetchingUpdateUserVendor: false,
      failedFetchingUpdateUserVendor: true,
    }
  },

  [REQUEST_UPDATE_USER_REGISTRATION_AGENT]: (state, action) => {
    return {
      ...state,
      fetchingUpdateUserAgent: true,
      failedFetchingUpdateUserAgent: false,
    }
  },
  [REQUEST_UPDATE_USER_REGISTRATION_AGENT_SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      fetchingUpdateUserAgent: false,
      failedFetchingUpdateUserAgent: false,
    }
  },
  [REQUEST_UPDATE_USER_REGISTRATION_AGENT_FAILURED]: (state, action) => {
    return {
      ...state,
      error: action.payload,
      fetchingUpdateUserAgent: false,
      failedFetchingUpdateUserAgent: true,
    }
  },

  [REQUEST_FETCH_USER_REGISTRATION_DETAILS]: (state, action) => {
    return {
      ...state,
      fetchingFetchUserDetails: true,
      failedFetchingFetchUserDetails: false,
    }
  },
  [REQUEST_FETCH_USER_REGISTRATION_DETAILS_SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      userDetails: action.payload.data,
      fetchingFetchUserDetails: false,
      failedFetchingFetchUserDetails: false,
    }
  },

  [REQUEST_FETCH_USER_REGISTRATION_DETAILS_FAILURED]: (state, action) => {
    return {
      ...state,
      error: action.payload,
      fetchingFetchUserDetails: false,
      failedFetchingFetchUserDetails: true,
    }
  },
  [REQUEST_ALL_VENDOR_CATEGORY_UPDATE]: (state, action) => {
    return {
      ...state,
      fetchingListAllVendorCategoryUpdate: true,
      fetchingListAllVendorCategoryUpdateFailed: false,
    }
  },
  [REQUEST_ALL_VENDOR_CATEGORY_UPDATE_SUCCESS]: (state, action) => {
    return {
      ...state,
      fetchingListAllVendorCategoryUpdate: false,
      fetchingListAllVendorCategoryUpdateFailed: false,
      listAllVendorCategoryUpdate: [...state.listAllVendorCategoryUpdate, ...action.payload.data],
    }
  },
  [REQUEST_ALL_VENDOR_CATEGORY_UPDATE_FAILURE]: (state, action) => {
    return {
      ...state,
      fetchingListAllVendorCategoryUpdate: false,
      fetchingListAllVendorCategoryUpdateFailed: true,
    }
  },

  [REQUEST_UPLOAD_FILE_DRE_LICENSE]: (state, action) => {
    return {
      ...state,
      fetchingUploadDreLicenseFile: true,
      fetchingListAllVendorCategoryFailed: false,
    }
  },
  [REQUEST_UPLOAD_FILE_DRE_LICENSE_SUCCESS]: (state, action) => {
    return {
      ...state,
      dreLicenseFile: action.payload,
      fetchingUploadDreLicenseFile: false,
      fetchingListAllVendorCategoryFailed: false,
    }
  },
  [REQUEST_UPLOAD_FILE_DRE_LICENSE_FAILURED]: (state, action) => {
    return {
      ...state,
      error: action.payload,
      fetchingUploadDreLicenseFile: false,
      fetchingListAllVendorCategoryFailed: true,
    }
  },

  [REQUEST_UPLOAD_FILE_DRIVER_LICENSE]: (state, action) => {
    return {
      ...state,
      fetchingUploadDriverLicense: true,
      failedFetchingUploadDriverLicense: false,
    }
  },
  [REQUEST_UPLOAD_FILE_DRIVER_LICENSE_SUCCESS]: (state, action) => {
    return {
      ...state,
      driverLicenseFile: action.payload,
      fetchingUploadDriverLicense: false,
      failedFetchingUploadDriverLicense: false,
    }
  },
  [REQUEST_UPLOAD_FILE_DRIVER_LICENSE_FAILURED]: (state, action) => {
    return {
      ...state,
      error: action.payload,
      fetchingUploadDriverLicense: false,
      failedFetchingUploadDriverLicense: true,
    }
  },

  [REQUEST_UPLOAD_FILE_UPDATE_USER]: (state, action) => {
    return {
      ...state,
      fetchingUploadPhoto: true,
      failedFetchingUploadPhoto: false,
    }
  },
  [REQUEST_UPLOAD_FILE_UPDATE_USER_SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      photo: action.payload,
      fetchingUploadPhoto: false,
      failedFetchingUploadPhoto: false,
    }
  },
  [REQUEST_UPLOAD_FILE_UPDATE_USER_FAILURED]: (state, action) => {
    return {
      ...state,
      error: action.payload,
      fetchingUploadPhoto: false,
      failedFetchingUploadPhoto: true,
    }
  },
})

export default reducer
export const namespace = 'userRegistrationUpdate'

export const handleUpdateUser = (userInfo) => async (dispatch, getState) => {
  return await callAPI({
    method: 'put',
    apiPath: '/user/update-user',
    actionTypes: [
      REQUEST_UPDATE_USER_REGISTRATION,
      REQUEST_UPDATE_USER_REGISTRATION_SUCCESS,
      REQUEST_UPDATE_USER_REGISTRATION_FAILURED,
    ],
    variables: userInfo,
    dispatch,
    getState,
  })
}

export const handleUpdateUserAgent = (userInfo) => async (dispatch, getState) => {
  return await callAPI({
    method: 'put',
    apiPath: '/user/update-user-agent',
    actionTypes: [
      REQUEST_UPDATE_USER_REGISTRATION_AGENT,
      REQUEST_UPDATE_USER_REGISTRATION_AGENT_SUCCESS,
      REQUEST_UPDATE_USER_REGISTRATION_AGENT_FAILURED,
    ],
    variables: userInfo,
    dispatch,
    getState,
  })
}

export const handleUpdateUserVendor = (userInfo) => async (dispatch, getState) => {
  return await callAPI({
    method: 'put',
    apiPath: '/user/update-user-vendor',
    actionTypes: [
      REQUEST_UPDATE_USER_REGISTRATION_VENDOR,
      REQUEST_UPDATE_USER_REGISTRATION_VENDOR_SUCCESS,
      REQUEST_UPDATE_USER_REGISTRATION_VENDOR_FAILURED,
    ],
    variables: userInfo,
    dispatch,
    getState,
  })
}

export const fetchUserDetails = (id) => async (dispatch, getState) => {
  return await callAPI({
    method: 'get',
    apiPath: '/user/information',
    actionTypes: [
      REQUEST_FETCH_USER_REGISTRATION_DETAILS,
      REQUEST_FETCH_USER_REGISTRATION_DETAILS_SUCCESS,
      REQUEST_FETCH_USER_REGISTRATION_DETAILS_FAILURED,
    ],
    variables: { userId: id },
    dispatch,
    getState,
  })
}

export const fetchAllVendorCategoryUpdate = (filters) => async (dispatch, getState) => {
  return await callAPI({
    method: 'get',
    apiPath: '/vendor-category/all',
    actionTypes: [
      REQUEST_ALL_VENDOR_CATEGORY_UPDATE,
      REQUEST_ALL_VENDOR_CATEGORY_UPDATE_SUCCESS,
      REQUEST_ALL_VENDOR_CATEGORY_UPDATE_FAILURE,
    ],
    variables: filters,
    dispatch,
    getState,
  })
}

export const confirmUpdateUserAction = (credentials) => ({
  type: CONFIRM_UPDATE_USER_ACTION,
  payload: credentials,
})

export const fetchUserDetailsAction = (credentials) => ({
  type: FETCH_USER_DETAIL_ACTION,
  payload: credentials,
})

export const getAllVendorCategoryUpdate = (filters) => ({
  type: GET_ALL_VENDOR_CATEGORY_UPDATE_ACTION,
  payload: filters,
})

export const uploadDreLicenseAction = (credentials) => ({
  type: UPLOAD_FILE_DRE_LICENSE_ACTION,
  payload: credentials,
})

export const uploadDriverLicenseAction = (credentials) => ({
  type: UPLOAD_FILE_DRIVER_LICENSE_ACTION,
  payload: credentials,
})

export const uploadPhotoUpdateUserAction = (credentials) => ({
  type: UPLOAD_PHOTO_UPDATE_USER_ACTION,
  payload: credentials,
})

export const handleUploadDreLicense = (file) => async (dispatch, getState) => {
  let formData = new FormData()
  formData.append('file', file)
  return await callAPI({
    method: 'post',
    apiPath: '/file/upload-files',
    actionTypes: [
      REQUEST_UPLOAD_FILE_DRE_LICENSE,
      REQUEST_UPLOAD_FILE_DRE_LICENSE_SUCCESS,
      REQUEST_UPLOAD_FILE_DRE_LICENSE_FAILURED,
    ],
    variables: formData,
    dispatch,
    getState,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export const handleUploadDriverLicense = (file) => async (dispatch, getState) => {
  let formData = new FormData()
  formData.append('file', file)
  return await callAPI({
    method: 'post',
    apiPath: '/file/upload-files',
    actionTypes: [
      REQUEST_UPLOAD_FILE_DRIVER_LICENSE,
      REQUEST_UPLOAD_FILE_DRIVER_LICENSE_SUCCESS,
      REQUEST_UPLOAD_FILE_DRIVER_LICENSE_FAILURED,
    ],
    variables: formData,
    dispatch,
    getState,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export const handleUploadUpdateUser = (file) => async (dispatch, getState) => {
  let formData = new FormData()
  formData.append('file', file)
  return await callAPI({
    method: 'post',
    apiPath: '/file/upload-files',
    actionTypes: [
      REQUEST_UPLOAD_FILE_UPDATE_USER,
      REQUEST_UPLOAD_FILE_UPDATE_USER_SUCCESS,
      REQUEST_UPLOAD_FILE_UPDATE_USER_FAILURED,
    ],
    variables: formData,
    dispatch,
    getState,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}
