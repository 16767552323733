import { createReducer } from 'redux-create-reducer'
import callAPI from '../../../utils/callAPI'
import { CourseType } from 'utils/enum'

export const REQUEST_FETCH_LESSON_DETAIL = 'REQUEST_FETCH_LESSON'
export const REQUEST_FETCH_LESSON_DETAIL_SUCCESS = 'REQUEST_FETCH_LESSON_DETAIL_SUCCESS'
export const REQUEST_FETCH_LESSON_DETAIL_FAILURED = 'REQUEST_FETCH_LESSON_DETAIL_FAILURED'

export const REQUEST_INIT_COURSE_DETAIL = 'REQUEST_INIT_COURSE_DETAIL'
export const REQUEST_INIT_COURSE_DETAIL_SUCCESS = 'REQUEST_INIT_COURSE_DETAIL_SUCCESS'
export const REQUEST_INIT_COURSE_DETAIL_FAILURE = 'REQUEST_INIT_COURSE_DETAIL_FAILURE'

export const REQUEST_UPDATE_COURSE = 'REQUEST_UPDATE_COURSE'
export const REQUEST_UPDATE_COURSE_SUCCESS = 'REQUEST_UPDATE_COURSE_SUCCESS'
export const REQUEST_UPDATE_COURSE_FAILURE = 'REQUEST_UPDATE_COURSE_FAILURE'

export const REQUEST_FETCH_LIST_LESSON = 'REQUEST_FETCH_LIST_LESSON'
export const REQUEST_FETCH_LIST_LESSON_SUCCESS = 'REQUEST_FETCH_LIST_LESSON_SUCCESS'
export const REQUEST_FETCH_LIST_LESSON_FAILURED = 'REQUEST_FETCH_LIST_LESSON_FAILURED'

export const REQUEST_UPDATE_LESSON = 'REQUEST_UPDATE_LESSON'
export const REQUEST_UPDATE_LESSON_SUCCESS = 'REQUEST_UPDATE_LESSON_SUCCESS'
export const REQUEST_UPDATE_LESSON_FAILURED = 'REQUEST_UPDATE_LESSON_FAILURED'

export const REQUEST_CREATE_LESSON = 'REQUEST_CREATE_LESSON'
export const REQUEST_CREATE_LESSON_SUCCESS = 'REQUEST_CREATE_LESSON_SUCCESS'
export const REQUEST_CREATE_LESSON_FAILURED = 'REQUEST_CREATE_LESSON_FAILURED'

export const REQUEST_DELETE_LESSON = 'REQUEST_DELETE_LESSON'
export const REQUEST_DELETE_LESSON_SUCCESS = 'REQUEST_DELETE_LESSON_SUCCESS'
export const REQUEST_DELETE_LESSON_FAILURED = 'REQUEST_DELETE_LESSON_FAILURED'

export const REQUEST_UPLOAD_FILE_UPDATE_LESSON = 'REQUEST_UPLOAD_FILE_UPDATE_LESSON'
export const REQUEST_UPLOAD_FILE_UPDATE_LESSON_SUCCESS = 'REQUEST_UPLOAD_FILE_UPDATE_LESSON_SUCCESS'
export const REQUEST_UPLOAD_FILE_UPDATE_LESSON_FAILURED =
  'REQUEST_UPLOAD_FILE_UPDATE_LESSON_FAILURED'

export const REQUEST_UPLOAD_FILE_UPDATE_COURSE = 'REQUEST_UPLOAD_FILE_UPDATE_COURSE'
export const REQUEST_UPLOAD_FILE_UPDATE_COURSE_SUCCESS = 'REQUEST_UPLOAD_FILE_UPDATE_COURSE_SUCCESS'
export const REQUEST_UPLOAD_FILE_UPDATE_COURSE_FAILURED =
  'REQUEST_UPLOAD_FILE_UPDATE_COURSE_FAILURED'

export const REQUEST_CREATE_QUIZ_DETAIL = 'REQUEST_CREATE_QUIZ_DETAIL'
export const REQUEST_CREATE_QUIZ_DETAIL_SUCCESS = 'REQUEST_CREATE_QUIZ_DETAIL_SUCCESS'
export const REQUEST_CREATE_QUIZ_DETAIL_FAILURE = 'REQUEST_CREATE_QUIZ_DETAIL_FAILURE'

export const REQUEST_UPDATE_QUIZ_DETAIL = 'REQUEST_UPDATE_QUIZ_DETAIL'
export const REQUEST_UPDATE_QUIZ_DETAIL_SUCCESS = 'REQUEST_UPDATE_QUIZ_DETAIL_SUCCESS'
export const REQUEST_UPDATE_QUIZ_DETAIL_FAILURE = 'REQUEST_UPDATE_QUIZ_DETAIL_FAILURE'

export const REQUEST_DELETE_QUIZ_DETAIL = 'REQUEST_DELETE_QUIZ_DETAIL'
export const REQUEST_DELETE_QUIZ_DETAIL_SUCCESS = 'REQUEST_DELETE_QUIZ_DETAIL_SUCCESS'
export const REQUEST_DELETE_QUIZ_DETAIL_FAILURE = 'REQUEST_DELETE_QUIZ_DETAIL_FAILURE'

export const REQUEST_CREATE_QUESTION_DETAIL = 'REQUEST_CREATE_QUESTION_DETAIL'
export const REQUEST_CREATE_QUESTION_DETAIL_SUCCESS = 'REQUEST_CREATE_QUESTION_DETAIL_SUCCESS'
export const REQUEST_CREATE_QUESTION_DETAIL_FAILURE = 'REQUEST_CREATE_QUESTION_DETAIL_FAILURE'

export const REQUEST_UPDATE_LESSONS = 'REQUEST_UPDATE_LESSONS'
export const REQUEST_UPDATE_LESSONS_SUCCESS = 'REQUEST_UPDATE_LESSONS_SUCCESS'
export const REQUEST_UPDATE_LESSONS_FAILURE = 'REQUEST_UPDATE_LESSONS_FAILURE'

export const REQUEST_DELETE_QUESTION_DETAIL = 'REQUEST_DELETE_QUESTION_DETAIL'
export const REQUEST_DELETE_QUESTION_DETAIL_SUCCESS = 'REQUEST_DELETE_QUESTION_DETAIL_SUCCESS'
export const REQUEST_DELETE_QUESTION_DETAIL_FAILURE = 'REQUEST_DELETE_QUESTION_DETAIL_FAILURE'

export const REQUEST_UPDATE_QUESTION_DETAIL = 'REQUEST_UPDATE_QUESTION_DETAIL'
export const REQUEST_UPDATE_QUESTION_DETAIL_SUCCESS = 'REQUEST_UPDATE_QUESTION_DETAIL_SUCCESS'
export const REQUEST_UPDATE_QUESTION_DETAIL_FAILURE = 'REQUEST_UPDATE_QUESTION_DETAIL_FAILURE'

export const REQUEST_MULTI_QUESTION_DETAIL = 'REQUEST_MULTI_QUESTION_DETAIL'
export const REQUEST_MULTI_QUESTION_DETAIL_SUCCESS = 'REQUEST_MULTI_QUESTION_DETAIL_SUCCESS'
export const REQUEST_MULTI_QUESTION_DETAIL_FAILURE = 'REQUEST_MULTI_QUESTION_DETAIL_FAILURE'

export const REQUEST_UPLOAD_EBOOK_THUMBNAIL_UPDATE = 'REQUEST_UPLOAD_EBOOK_THUMBNAIL_UPDATE'
export const REQUEST_UPLOAD_EBOOK_THUMBNAIL_UPDATE_SUCCESS =
  'REQUEST_UPLOAD_EBOOK_THUMBNAIL_UPDATE_SUCCESS'
export const REQUEST_UPLOAD_EBOOK_THUMBNAIL_UPDATE_FAILURED =
  'REQUEST_UPLOAD_EBOOK_THUMBNAIL_UPDATE_FAILURED'

export const REQUEST_UPLOAD_EBOOK_UPDATE = 'REQUEST_UPLOAD_EBOOK_UPDATE'
export const REQUEST_UPLOAD_EBOOK_UPDATE_SUCCESS = 'REQUEST_UPLOAD_EBOOK_UPDATE_SUCCESS'
export const REQUEST_UPLOAD_EBOOK_UPDATE_FAILURED = 'REQUEST_UPLOAD_EBOOK_UPDATE_FAILURED'

export const REQUEST_INIT_LESSON = 'REQUEST_INIT_LESSON'
export const REQUEST_INIT_LESSON_SUCCESS = 'REQUEST_INIT_LESSON_SUCCESS'
export const REQUEST_INIT_LESSON_FAILURE = 'REQUEST_INIT_LESSON_FAILURE'

export const REQUEST_INIT_QUIZ = 'REQUEST_INIT_QUIZ'
export const REQUEST_INIT_QUIZ_SUCCESS = 'REQUEST_INIT_QUIZ_SUCCESS'
export const REQUEST_INIT_QUIZ_FAILURE = 'REQUEST_INIT_QUIZ_FAILURE'

export const CONFIRM_UPDATE_LESSON_ACTION = 'CONFIRM_UPDATE_LESSON_ACTION'
export const CONFIRM_CREATE_LESSON_ACTION = 'CONFIRM_CREATE_LESSON_ACTION'
export const CONFIRM_UPDATE_COURSE_ACTION = 'CONFIRM_UPDATE_COURSE_ACTION'
export const CONFIRM_DELETE_LESSON_ACTION = 'CONFIRM_DELETE_LESSON_ACTION'
export const UPLOAD_PHOTO_UPDATE_LESSON_ACTION = 'UPLOAD_PHOTO_UPDATE_LESSON_ACTION'
export const UPLOAD_PHOTO_UPDATE_COURSE_ACTION = 'UPLOAD_PHOTO_UPDATE_COURSE_ACTION'
export const CHANGE_QUESTION_DETAIL_ACTION = 'CHANGE_QUESTION_DETAIL_ACTION'
export const VALIDATE_QUIZ_DETAIL_ACTION = 'VALIDATE_QUIZ_DETAIL_ACTION'
export const SHOW_MODAL_CONFIRM_CANCEL_COURSE = 'SHOW_MODAL_CONFIRM_CANCEL_COURSE'
export const CLEAR_UPDATE_LESSON_AVATAR = 'CLEAR_UPDATE_LESSON_AVATAR'

export const UPDATE_UPDATE_COURSE_DATA = 'UPDATE_UPDATE_COURSE_DATA'
export const UPDATE_UPDATE_VIDEO_DETAIL = 'UPDATE_UPDATE_VIDEO_DETAIL'
export const CLEAR_UPDATE_VIDEO_DETAIL = 'CLEAR_UPDATE_VIDEO_DETAIL'
export const SET_CURRENT_EBOOK_DATA_UPDATE = 'SET_CURRENT_EBOOK_DATA_UPDATE'
export const THUMBNAIL_SELECTED_UPDATE = 'THUMBNAIL_SELECTED_UPDATE'
export const EBOOK_SELECTED_UPDATE = 'EBOOK_SELECTED_UPDATE'
export const UPDATE_CURRENT_EBOOK_UPDATE = 'UPDATE_CURRENT_EBOOK_UPDATE'
export const CONFIRM_EBOOK_FORM_UPDATE = 'CONFIRM_EBOOK_FORM_UPDATE'
export const ON_CONFIRM_UPDATE_COURSE = 'ON_CONFIRM_UPDATE_COURSE'
export const DELETE_QUIZ_ACTION = 'DELETE_QUIZ_ACTION'

const defaultState = {
  courseDataUpdate: {
    courseId: '',
    generalInformation: {},
    ebook: null,
    videos: [],
    quizzes: [],
    listDeleteQuizzes: [],
  },

  totalProcessingQuiz: 0,
  updatingCourse: false,
  failedUpdatingCourse: false,

  creatingOrUpdatingQuiz: true,

  currentEbookUpdate: {},
  uploadingThumbnail: false,
  failedUploadingThumbnail: false,

  uploadingEbook: false,
  failedUploadingEbook: false,

  videoDetailUpdate: {},
  updateLessonData: [],
  fetchingUpdateLesson: false,
  failedFetchingUpdateLesson: false,

  fetchingCreateLesson: false,
  failedFetchingCreateLesson: false,

  lessonDetail: [],
  fetchingLessonDetail: false,
  failedFetchingLessonDetail: false,

  lessonList: [],
  fetchingLessonList: false,
  failedFetchingLessonList: false,

  fetchingCourseDetail: false,
  fetchingCourseDetailSuccess: false,
  failedFetchingCourseDetail: false,

  courseAvatar: [],
  fetchingUploadAvatar: false,
  failedFetchingUploadAvatar: false,

  showConfirmCancelCourse: false,

  thumbnailUpdateLessonData: [],
  fetchingThumbnailUpdateLessonData: false,
  failedFetchingThumbnailUpdateLessonData: false,
}

const reducer = createReducer(defaultState, {
  [REQUEST_UPDATE_COURSE]: (state, action) => {
    return {
      ...state,
      updatingCourse: true,
      failedUpdatingCourse: false,
    }
  },
  [REQUEST_UPDATE_COURSE_SUCCESS]: (state, action) => {
    return {
      ...state,
      updatingCourse: false,
      failedUpdatingCourse: false,
    }
  },
  [REQUEST_UPDATE_COURSE_FAILURE]: (state, action) => {
    return {
      ...state,
      updatingCourse: false,
      failedUpdatingCourse: true,
    }
  },

  [REQUEST_CREATE_QUIZ_DETAIL_SUCCESS]: (state, action) => ({
    ...state,
    creatingOrUpdatingQuiz: !action.meta?.variables?.theLast,
  }),

  [REQUEST_UPDATE_QUIZ_DETAIL_SUCCESS]: (state, action) => ({
    ...state,
    creatingOrUpdatingQuiz: !action.meta?.variables?.theLast,
  }),

  [REQUEST_INIT_LESSON_SUCCESS]: (state, action) => {
    return {
      ...state,
      courseDataUpdate: {
        ...state.courseDataUpdate,
        ebook:
          state.courseDataUpdate.generalInformation.type === CourseType.EBOOK
            ? action.payload.data.length
              ? action.payload.data[0]
              : null
            : null,
        videos:
          state.courseDataUpdate.generalInformation.type === CourseType.VIDEO
            ? action.payload.data
            : [],
      },
    }
  },
  [REQUEST_INIT_QUIZ_SUCCESS]: (state, action) => {
    const quizzes = action.payload.data
    const modifyQuizzes = quizzes.map((quiz) => ({
      ...quiz,
      isNew: false,
      id: quiz._id,
      locationError: { isError: false, message: '' },
      questions: quiz.questions.map((question) => ({
        ...question,
        id: question._id,
        titleError: { isError: false, message: '' },
        answers: question.answers.map((answer) => ({
          ...answer,
          valueError: { isError: false, message: '' },
        })),
      })),
    }))
    return {
      ...state,
      courseDataUpdate: {
        ...state.courseDataUpdate,
        quizzes: modifyQuizzes,
      },
    }
  },
  [CLEAR_UPDATE_LESSON_AVATAR]: (state, action) => {
    return {
      ...state,
      thumbnailUpdateLessonData: null,
    }
  },
  [REQUEST_UPDATE_LESSON]: (state, action) => {
    return {
      ...state,
      fetchingUpdateLesson: true,
      failedFetchingUpdateLesson: false,
    }
  },
  [REQUEST_UPDATE_LESSON_SUCCESS]: (state, action) => {
    return {
      ...state,
      updateLessonData: action.payload,
      fetchingUpdateLesson: false,
      failedFetchingUpdateLesson: false,
    }
  },
  [REQUEST_UPDATE_LESSON_FAILURED]: (state, action) => {
    return {
      ...state,
      error: action.payload,
      fetchingCreateUser: false,
      failedFetchingCreateUser: true,
    }
  },

  [REQUEST_FETCH_LIST_LESSON]: (state, action) => {
    return {
      ...state,
      fetchingLessonList: true,
      failedFetchingLessonList: false,
    }
  },
  [REQUEST_FETCH_LIST_LESSON_SUCCESS]: (state, action) => {
    return {
      ...state,
      courseDataUpdate: {
        ...state.courseDataUpdate,
        ebook:
          state.courseDataUpdate.generalInformation?.type === CourseType.EBOOK
            ? action.payload.data?.[0]
            : state.courseDataUpdate.ebook,
        videos:
          state.courseDataUpdate.generalInformation?.type === CourseType.VIDEO
            ? action.payload.data
            : state.courseDataUpdate.videos,
      },
      fetchingLessonList: false,
      failedFetchingLessonList: false,
    }
  },

  [REQUEST_DELETE_LESSON_SUCCESS]: (state, action) => ({
    ...state,
    courseDataUpdate: {
      ...state.courseDataUpdate,
      videos:
        state.courseDataUpdate.generalInformation?.type === CourseType.VIDEO
          ? state.courseDataUpdate.videos?.filter(
              (item) => item._id !== action.metadata.variables.id,
            )
          : state.courseDataUpdate.videos,
    },
  }),

  [REQUEST_FETCH_LIST_LESSON_FAILURED]: (state, action) => {
    return {
      ...state,
      error: action.payload,
      fetchingLessonList: false,
      failedFetchingLessonList: true,
    }
  },

  [REQUEST_FETCH_LESSON_DETAIL]: (state, action) => {
    return {
      ...state,
      fetchingLessonDetail: true,
      failedFetchingLessonDetail: false,
    }
  },
  [REQUEST_FETCH_LESSON_DETAIL_SUCCESS]: (state, action) => {
    return {
      ...state,
      lessonDetail: action.payload,
      fetchingLessonDetail: false,
      failedFetchingLessonDetail: false,
    }
  },
  [REQUEST_FETCH_LESSON_DETAIL_FAILURED]: (state, action) => {
    return {
      ...state,
      error: action.payload,
      fetchingLessonDetail: false,
      failedFetchingLessonDetail: true,
    }
  },

  [REQUEST_CREATE_LESSON]: (state, action) => {
    return {
      ...state,
      fetchingCreateLesson: true,
      failedFetchingCreateLesson: false,
    }
  },
  [REQUEST_CREATE_LESSON_SUCCESS]: (state, action) => {
    return {
      ...state,
      courseDataUpdate: {
        ...state.courseDataUpdate,
      },
      fetchingCreateLesson: false,
      failedFetchingCreateLesson: false,
    }
  },
  [REQUEST_CREATE_LESSON_FAILURED]: (state, action) => {
    return {
      ...state,
      error: action.payload,
      fetchingCreateLesson: false,
      failedFetchingCreateLesson: true,
    }
  },

  [REQUEST_INIT_COURSE_DETAIL]: (state, action) => {
    return {
      ...state,
      fetchingCourseDetail: true,
      failedFetchingCourseDetail: false,
    }
  },
  [REQUEST_INIT_COURSE_DETAIL_SUCCESS]: (state, action) => {
    return {
      ...state,
      courseDataUpdate: {
        ...state.courseDataUpdate,
        courseId: action.payload.data._id,
        generalInformation: {
          ...state.courseDataUpdate.generalInformation,
          ...action.payload.data,
          description: action.payload.data.description ?? '',
        },
      },
      fetchingCourseDetail: false,
      fetchingCourseDetailSuccess: true,
      failedFetchingCourseDetail: false,
      updatingCourse: false,
    }
  },
  [REQUEST_INIT_COURSE_DETAIL_FAILURE]: (state, action) => {
    return {
      ...state,
      fetchingCourseDetail: false,
      fetchingCourseDetailSuccess: false,
      failedFetchingCourseDetail: true,
    }
  },

  [REQUEST_UPLOAD_FILE_UPDATE_LESSON]: (state, action) => {
    return {
      ...state,
      fetchingThumbnailUpdateLessonData: true,
      failedFetchingThumbnailUpdateLessonData: false,
    }
  },
  [REQUEST_UPLOAD_FILE_UPDATE_LESSON_SUCCESS]: (state, action) => {
    return {
      ...state,
      thumbnailUpdateLessonData: action.payload.data,
      fetchingThumbnailUpdateLessonData: false,
      failedFetchingThumbnailUpdateLessonData: false,
    }
  },
  [REQUEST_UPLOAD_FILE_UPDATE_LESSON_FAILURED]: (state, action) => {
    return {
      ...state,
      error: action.payload,
      fetchingThumbnailUpdateLessonData: false,
      failedFetchingThumbnailUpdateLessonData: true,
    }
  },

  [REQUEST_UPLOAD_FILE_UPDATE_COURSE]: (state, action) => {
    return {
      ...state,
      fetchingUploadAvatar: true,
      failedFetchingUploadAvatar: false,
    }
  },
  [REQUEST_UPLOAD_FILE_UPDATE_COURSE_SUCCESS]: (state, action) => {
    return {
      ...state,
      courseDataUpdate: {
        ...state.courseDataUpdate,
        generalInformation: {
          ...state.courseDataUpdate.generalInformation,
          thumbnail: action.payload.data.link,
        },
      },
      fetchingUploadAvatar: false,
      failedFetchingUploadAvatar: false,
    }
  },
  [REQUEST_UPLOAD_FILE_UPDATE_COURSE_FAILURED]: (state, action) => {
    return {
      ...state,
      error: action.payload,
      fetchingUploadAvatar: false,
      failedFetchingUploadAvatar: true,
    }
  },

  [REQUEST_UPLOAD_EBOOK_THUMBNAIL_UPDATE]: (state, action) => ({
    ...state,
    uploadingThumbnail: true,
    failedUploadingThumbnail: false,
  }),
  [REQUEST_UPLOAD_EBOOK_THUMBNAIL_UPDATE_SUCCESS]: (state, action) => ({
    ...state,
    uploadingThumbnail: false,
    failedUploadingThumbnail: false,
  }),
  [REQUEST_UPLOAD_EBOOK_THUMBNAIL_UPDATE_FAILURED]: (state, action) => ({
    ...state,
    uploadingThumbnail: false,
    failedUploadingThumbnail: true,
  }),
  [ON_CONFIRM_UPDATE_COURSE]: (state, action) => ({
    ...state,
    totalProcessingQuiz: action.payload.quizzes?.length,
  }),
  [REQUEST_MULTI_QUESTION_DETAIL_SUCCESS]: (state, action) => ({
    ...state,
    totalProcessingQuiz: state.totalProcessingQuiz - 1,
  }),

  [REQUEST_UPLOAD_EBOOK_UPDATE]: (state, action) => ({
    ...state,
    uploadingEbook: true,
    failedUploadingEbook: false,
  }),
  [REQUEST_UPLOAD_EBOOK_UPDATE_SUCCESS]: (state, action) => ({
    ...state,
    uploadingEbook: false,
    failedUploadingEbook: false,
  }),
  [REQUEST_UPLOAD_EBOOK_UPDATE_FAILURED]: (state, action) => ({
    ...state,
    uploadingEbook: false,
    failedUploadingEbook: true,
  }),

  [SHOW_MODAL_CONFIRM_CANCEL_COURSE]: (state, action) => {
    return {
      ...state,
      showConfirmCancelCourse: action.payload,
    }
  },

  [UPDATE_UPDATE_COURSE_DATA]: (state, action) => {
    return {
      ...state,
      courseDataUpdate: action.payload,
    }
  },
  [UPDATE_UPDATE_VIDEO_DETAIL]: (state, action) => {
    return {
      ...state,
      videoDetailUpdate: action.payload,
    }
  },
  [CLEAR_UPDATE_VIDEO_DETAIL]: (state, action) => {
    return {
      ...state,
      videoDetailUpdate: {},
    }
  },

  [UPDATE_CURRENT_EBOOK_UPDATE]: (state, action) => {
    return {
      ...state,
      currentEbookUpdate: { ...state.currentEbookUpdate, ...action.payload },
    }
  },

  [CONFIRM_EBOOK_FORM_UPDATE]: (state, action) => ({
    ...state,
    courseDataUpdate: {
      ...state.courseDataUpdate,
      ebook: { ...state.ebook, ...action.payload },
    },
    currentEbookUpdate: {},
  }),
  [SET_CURRENT_EBOOK_DATA_UPDATE]: (state, action) => ({
    ...state,
    currentEbookUpdate: action.payload,
  }),

  [DELETE_QUIZ_ACTION]: (state, action) => {
    const quizId = action.payload
    const newQuizzes = state.courseDataUpdate.quizzes.filter((quiz) => quiz.id !== quizId)

    return {
      ...state,
      courseDataUpdate: {
        ...state.courseDataUpdate,
        quizzes: newQuizzes,
        listDeleteQuizzes: [...state.courseDataUpdate.listDeleteQuizzes, quizId],
      },
    }
  },
})

export default reducer
export const namespace = 'courseManagementUpdate'

export const fetchInitCourseDetail =
  ({ id }) =>
  async (dispatch, getState) => {
    return await callAPI({
      method: 'get',
      apiPath: `/course/${id}`,
      actionTypes: [
        REQUEST_INIT_COURSE_DETAIL,
        REQUEST_INIT_COURSE_DETAIL_SUCCESS,
        REQUEST_INIT_COURSE_DETAIL_FAILURE,
      ],
      dispatch,
      getState,
    })
  }

export const fetchInitLesson = (filters) => async (dispatch, getState) => {
  return await callAPI({
    method: 'get',
    apiPath: '/lesson',
    actionTypes: [REQUEST_INIT_LESSON, REQUEST_INIT_LESSON_SUCCESS, REQUEST_INIT_LESSON_FAILURE],
    variables: { ...filters },
    dispatch,
    getState,
  })
}

export const fetchInitQuiz =
  ({ courseId }) =>
  async (dispatch, getState) => {
    return await callAPI({
      method: 'get',
      apiPath: 'quiz',
      actionTypes: [REQUEST_INIT_QUIZ, REQUEST_INIT_QUIZ_SUCCESS, REQUEST_INIT_QUIZ_FAILURE],
      variables: { courseId },
      dispatch,
      getState,
    })
  }

export const handleFetchLessondDetail =
  ({ id }) =>
  async (dispatch, getState) => {
    return await callAPI({
      method: 'get',
      apiPath: `/lesson/${id}`,
      actionTypes: [
        REQUEST_FETCH_LESSON_DETAIL,
        REQUEST_FETCH_LESSON_DETAIL_SUCCESS,
        REQUEST_FETCH_LESSON_DETAIL_FAILURED,
      ],
      dispatch,
      getState,
    })
  }

export const handleFetchListLesson = (filters) => async (dispatch, getState) => {
  return await callAPI({
    method: 'get',
    apiPath: `/lesson`,
    actionTypes: [
      REQUEST_FETCH_LIST_LESSON,
      REQUEST_FETCH_LIST_LESSON_SUCCESS,
      REQUEST_FETCH_LIST_LESSON_FAILURED,
    ],
    variables: { ...filters },
    dispatch,
    getState,
  })
}

export const handleUpdateLesson =
  ({ lessonInfo }) =>
  async (dispatch, getState) => {
    return await callAPI({
      method: 'put',
      apiPath: `/lesson/${lessonInfo.id}`,
      actionTypes: [
        REQUEST_UPDATE_LESSON,
        REQUEST_UPDATE_LESSON_SUCCESS,
        REQUEST_UPDATE_LESSON_FAILURED,
      ],
      variables: {
        ...lessonInfo,
      },
      dispatch,
      getState,
    })
  }

export const handleCreateLesson =
  ({ lessonInfo }) =>
  async (dispatch, getState) => {
    return await callAPI({
      method: 'post',
      apiPath: '/lesson',
      actionTypes: [
        REQUEST_CREATE_LESSON,
        REQUEST_CREATE_LESSON_SUCCESS,
        REQUEST_CREATE_LESSON_FAILURED,
      ],
      variables: {
        ...lessonInfo,
      },
      dispatch,
      getState,
    })
  }

export const handleDeleteLesson =
  ({ id }) =>
  async (dispatch, getState) => {
    return await callAPI({
      method: 'delete',
      apiPath: `/lesson/${id}`,
      actionTypes: [
        REQUEST_DELETE_LESSON,
        REQUEST_DELETE_LESSON_SUCCESS,
        REQUEST_DELETE_LESSON_FAILURED,
      ],
      variables: {
        id,
      },
      dispatch,
      getState,
    })
  }

export const handleUpdateCourse = (courseDataUpdate) => async (dispatch, getState) => {
  return await callAPI({
    method: 'put',
    apiPath: `/course/${courseDataUpdate.courseId}`,
    actionTypes: [
      REQUEST_UPDATE_COURSE,
      REQUEST_UPDATE_COURSE_SUCCESS,
      REQUEST_UPDATE_COURSE_FAILURE,
    ],
    variables: courseDataUpdate.generalInformation,
    dispatch,
    getState,
  })
}

export const handleUploadUpdateFile = (data) => async (dispatch, getState) => {
  let formData = new FormData()
  formData.append('file', data.action.file)
  return await callAPI({
    method: 'post',
    apiPath: '/file/upload-files',
    actionTypes:
      data.type === 'lesson'
        ? [
            REQUEST_UPLOAD_FILE_UPDATE_LESSON,
            REQUEST_UPLOAD_FILE_UPDATE_LESSON_SUCCESS,
            REQUEST_UPLOAD_FILE_UPDATE_LESSON_FAILURED,
          ]
        : [
            REQUEST_UPLOAD_FILE_UPDATE_COURSE,
            REQUEST_UPLOAD_FILE_UPDATE_COURSE_SUCCESS,
            REQUEST_UPLOAD_FILE_UPDATE_COURSE_FAILURED,
          ],
    variables: formData,
    dispatch,
    getState,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export const fetchUpdateQuiz = (params) => async (dispatch, getState) => {
  return await callAPI({
    method: 'put',
    apiPath: `/quiz/${params.quizId}`,
    actionTypes: [
      REQUEST_UPDATE_QUIZ_DETAIL,
      REQUEST_UPDATE_QUIZ_DETAIL_SUCCESS,
      REQUEST_UPDATE_QUIZ_DETAIL_FAILURE,
    ],
    variables: params,
    dispatch,
    getState,
  })
}

export const fetchDeleteQuiz = (quizId) => async (dispatch, getState) => {
  return await callAPI({
    method: 'delete',
    apiPath: `/quiz/${quizId}`,
    actionTypes: [
      REQUEST_DELETE_QUIZ_DETAIL,
      REQUEST_DELETE_QUIZ_DETAIL_SUCCESS,
      REQUEST_DELETE_QUIZ_DETAIL_FAILURE,
    ],
    dispatch,
    getState,
  })
}

export const fetchCreateQuestion =
  ({ quizId, question }) =>
  async (dispatch, getState) => {
    return await callAPI({
      method: 'post',
      apiPath: '/question',
      actionTypes: [
        REQUEST_CREATE_QUESTION_DETAIL,
        REQUEST_CREATE_QUESTION_DETAIL_SUCCESS,
        REQUEST_CREATE_QUESTION_DETAIL_FAILURE,
      ],
      variables: {
        quizId,
        title: question.title,
        type: question.type,
        answers: question.answers,
      },
      dispatch,
      getState,
    })
  }

export const updateLesson =
  ({ courseId, lessons }) =>
  async (dispatch, getState) => {
    return await callAPI({
      method: 'post',
      apiPath: '/lesson/multi',
      actionTypes: [
        REQUEST_UPDATE_LESSONS,
        REQUEST_UPDATE_LESSONS_SUCCESS,
        REQUEST_UPDATE_LESSONS_FAILURE,
      ],
      variables: {
        courseId,
        lessons,
      },
      dispatch,
      getState,
    })
  }

export const fetchDeleteQuestion = (payload) => async (dispatch, getState) => {
  return await callAPI({
    method: 'delete',
    apiPath: `/question/${payload.questionId}`,
    actionTypes: [
      REQUEST_DELETE_QUESTION_DETAIL,
      REQUEST_DELETE_QUESTION_DETAIL_SUCCESS,
      REQUEST_DELETE_QUESTION_DETAIL_FAILURE,
    ],
    variables: payload,
    dispatch,
    getState,
  })
}

export const fetchMultiQuestion = (payload) => async (dispatch, getState) => {
  return await callAPI({
    method: 'post',
    apiPath: 'question/multi',
    actionTypes: [
      REQUEST_MULTI_QUESTION_DETAIL,
      REQUEST_MULTI_QUESTION_DETAIL_SUCCESS,
      REQUEST_MULTI_QUESTION_DETAIL_FAILURE,
    ],
    variables: payload,
    dispatch,
    getState,
  })
}

export const fetchCreateQuiz = (variables) => async (dispatch, getState) => {
  return await callAPI({
    method: 'post',
    apiPath: '/quiz',
    actionTypes: [
      REQUEST_CREATE_QUIZ_DETAIL,
      REQUEST_CREATE_QUIZ_DETAIL_SUCCESS,
      REQUEST_CREATE_QUIZ_DETAIL_FAILURE,
    ],
    variables,
    dispatch,
    getState,
  })
}

export const ebookUploadFileUpdate =
  ({ file, metadata }) =>
  async (dispatch, getState) => {
    let formData = new FormData()
    formData.append('file', file)
    formData.append('metadata', metadata)
    return await callAPI({
      method: 'post',
      apiPath: '/file/upload-files',
      actionTypes:
        metadata.type === 'thumbnail'
          ? [
              REQUEST_UPLOAD_EBOOK_THUMBNAIL_UPDATE,
              REQUEST_UPLOAD_EBOOK_THUMBNAIL_UPDATE_SUCCESS,
              REQUEST_UPLOAD_EBOOK_THUMBNAIL_UPDATE_FAILURED,
            ]
          : [
              REQUEST_UPLOAD_EBOOK_UPDATE,
              REQUEST_UPLOAD_EBOOK_UPDATE_SUCCESS,
              REQUEST_UPLOAD_EBOOK_UPDATE_FAILURED,
            ],
      variables: formData,
      dispatch,
      getState,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }

export const confirmUpdateLessonAction = (credentials) => ({
  type: CONFIRM_UPDATE_LESSON_ACTION,
  payload: credentials,
})

export const confirmCreateLessonAction = (credentials) => ({
  type: CONFIRM_CREATE_LESSON_ACTION,
  payload: credentials,
})

export const confirmUpdateCourseAction = (credentials) => ({
  type: CONFIRM_UPDATE_COURSE_ACTION,
  payload: credentials,
})

export const confirmDeleteLessonAction = (payload) => ({
  type: CONFIRM_DELETE_LESSON_ACTION,
  payload,
})

export const uploadPhotoUpdateLessonAction = (credentials) => ({
  type: UPLOAD_PHOTO_UPDATE_LESSON_ACTION,
  payload: credentials,
})

export const uploadUpdateCourseAvatarAction = (credentials) => ({
  type: UPLOAD_PHOTO_UPDATE_COURSE_ACTION,
  payload: credentials,
})

export const changeQuestionAction = (payload) => ({
  type: CHANGE_QUESTION_DETAIL_ACTION,
  payload,
})

export const validateQuizAction = () => ({
  type: VALIDATE_QUIZ_DETAIL_ACTION,
})

export const changeShowConfirmDeleteCourse = (payload) => ({
  type: SHOW_MODAL_CONFIRM_CANCEL_COURSE,
  payload,
})

export const clearUpdateLessonAvatarAction = () => ({
  type: CLEAR_UPDATE_LESSON_AVATAR,
})

export const updateCourseDataUpdate = (data) => ({
  type: UPDATE_UPDATE_COURSE_DATA,
  payload: data,
})

export const updateVideoDetailUpdateData = (data) => ({
  type: UPDATE_UPDATE_VIDEO_DETAIL,
  payload: data,
})

export const clearVideoDetailUpdateData = (data) => ({
  type: CLEAR_UPDATE_VIDEO_DETAIL,
  payload: data,
})

export const thumbnailSelectedUpdate = (payload) => ({
  type: THUMBNAIL_SELECTED_UPDATE,
  payload: payload,
})

export const ebookSelectedUpdate = (payload) => ({
  type: EBOOK_SELECTED_UPDATE,
  payload: payload,
})

export const submitEbookFormUpdate = (payload) => ({
  type: CONFIRM_EBOOK_FORM_UPDATE,
  payload: payload,
})

export const setCurrentEbookDataUpdate = (payload) => ({
  type: SET_CURRENT_EBOOK_DATA_UPDATE,
  payload: payload,
})

export const updateCurrentEBookUpdate = (payload) => ({
  type: UPDATE_CURRENT_EBOOK_UPDATE,
  payload: payload,
})

export const onConfirmUpdateCourse = (courseInfo) => ({
  type: ON_CONFIRM_UPDATE_COURSE,
  payload: courseInfo,
})

export const deleteQuizAction = (quizId) => ({
  type: DELETE_QUIZ_ACTION,
  payload: quizId,
})
