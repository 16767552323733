import { all, fork, put, takeLatest } from 'redux-saga/effects'
import { VENDOR_MANAGEMENT_SEARCH_ACTION, fetchVendorManagement } from './index'
import { PAGINATION_DEFAULT_LIMIT, PAGINATION_DEFAULT_PAGE, SORT_DEFAULT } from 'utils/constants'

function* initPage() {
  yield put(
    fetchVendorManagement({
      limit: PAGINATION_DEFAULT_LIMIT,
      page: PAGINATION_DEFAULT_PAGE,
      sortCreatedAt: SORT_DEFAULT,
    }),
  )
}

function* listenEvents() {
  yield takeLatest(VENDOR_MANAGEMENT_SEARCH_ACTION, function* (action) {
    yield put(fetchVendorManagement(action.payload))
  })
}

export default function* loadVendorManagement() {
  yield all([fork(initPage), fork(listenEvents)])
}
