import { createReducer } from 'redux-create-reducer'
import callAPI from 'utils/callAPI'

export const COURSE_MANAGEMENT_REQUEST_LIST = 'COURSE_MANAGEMENT_REQUEST_LIST'
export const COURSE_MANAGEMENT_REQUEST_LIST_SUCCESS = 'COURSE_MANAGEMENT_REQUEST_LIST_SUCCESS'
export const COURSE_MANAGEMENT_REQUEST_LIST_FAILURE = 'COURSE_MANAGEMENT_REQUEST_LIST_FAILURE'

export const COURSE_MANAGEMENT_SEARCH_ACTION = 'COURSE_MANAGEMENT_SEARCH_ACTION'

export const COURSE_MANAGEMENT_DELETE = 'COURSE_MANAGEMENT_DELETE'
export const COURSE_MANAGEMENT_DELETE_ACTION = 'CONFIRM_COURSE_MANAGEMENT_ACTION'
export const COURSE_MANAGEMENT_DELETE_SUCCESS = 'COURSE_MANAGEMENT_DELETE_SUCCESS'
export const COURSE_MANAGEMENT_DELETE_FAILURE = 'COURSE_MANAGEMENT_DELETE_FAILURE'

export const CHANGE_COURSE_FILTER = 'CHANGE_COURSE_FILTER'

const defaultState = {
  data: [],
  fetchingList: true,
  fetchingListFailed: false,
}

const reducer = createReducer(defaultState, {
  [COURSE_MANAGEMENT_REQUEST_LIST]: (state) => {
    return {
      ...state,
      fetchingList: true,
      fetchingListFailed: false,
    }
  },
  [COURSE_MANAGEMENT_REQUEST_LIST_SUCCESS]: (state, action) => {
    return {
      ...state,
      data: action.payload,
      fetchingList: false,
      fetchingListFailed: false,
    }
  },
  [COURSE_MANAGEMENT_REQUEST_LIST_FAILURE]: (state) => {
    return {
      ...state,
      fetchingList: false,
      fetchingListFailed: true,
    }
  },
  [COURSE_MANAGEMENT_DELETE]: (state) => {
    return {
      ...state,
      fetchingList: true,
      fetchingListFailed: false,
    }
  },
  [COURSE_MANAGEMENT_DELETE_SUCCESS]: (state) => {
    return {
      ...state,
      fetchingList: false,
      fetchingListFailed: false,
    }
  },
  [COURSE_MANAGEMENT_DELETE_FAILURE]: (state) => {
    return {
      ...state,
      fetchingList: false,
      fetchingListFailed: true,
    }
  },
})

export default reducer
export const namespace = 'courseManagement'

export const searchCourseAction = (filters) => ({
  type: COURSE_MANAGEMENT_SEARCH_ACTION,
  payload: filters,
})

export const deleteCourseAction = (id) => ({
  type: COURSE_MANAGEMENT_DELETE_ACTION,
  payload: id,
})

export const fetchCourseManagement = (filters) => async (dispatch, getState) => {
  return await callAPI({
    method: 'get',
    apiPath: '/course',
    actionTypes: [
      COURSE_MANAGEMENT_REQUEST_LIST,
      COURSE_MANAGEMENT_REQUEST_LIST_SUCCESS,
      COURSE_MANAGEMENT_REQUEST_LIST_FAILURE,
    ],
    variables: { ...filters },
    dispatch,
    getState,
  })
}

export const fetchDeleteCourseManagement = (id) => async (dispatch, getState) => {
  return await callAPI({
    method: 'delete',
    apiPath: `/course/${id}`,
    actionTypes: [
      COURSE_MANAGEMENT_DELETE,
      COURSE_MANAGEMENT_DELETE_SUCCESS,
      COURSE_MANAGEMENT_DELETE_FAILURE,
    ],
    dispatch,
    getState,
  })
}
