import { all, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import {
  CONFIRM_CREATE_LESSON_ACTION,
  CONFIRM_DELETE_LESSON_ACTION,
  CONFIRM_UPDATE_COURSE_ACTION,
  CONFIRM_UPDATE_LESSON_ACTION,
  fetchInitQuiz,
  handleCreateLesson,
  handleDeleteLesson,
  fetchInitCourseDetail,
  fetchInitLesson,
  handleFetchListLesson,
  handleUpdateCourse,
  handleUpdateLesson,
  handleUploadUpdateFile,
  REQUEST_CREATE_LESSON_FAILURED,
  REQUEST_CREATE_LESSON_SUCCESS,
  REQUEST_DELETE_LESSON_SUCCESS,
  REQUEST_UPDATE_LESSON_FAILURED,
  REQUEST_UPDATE_LESSON_SUCCESS,
  UPLOAD_PHOTO_UPDATE_COURSE_ACTION,
  fetchMultiQuestion,
  UPLOAD_PHOTO_UPDATE_LESSON_ACTION,
  updateVideoDetailUpdateData,
  REQUEST_UPLOAD_FILE_UPDATE_LESSON_SUCCESS,
  REQUEST_UPLOAD_FILE_UPDATE_COURSE_SUCCESS,
  updateCourseDataUpdate,
  REQUEST_FETCH_LIST_LESSON_SUCCESS,
  THUMBNAIL_SELECTED_UPDATE,
  EBOOK_SELECTED_UPDATE,
  ebookUploadFileUpdate,
  REQUEST_UPLOAD_EBOOK_THUMBNAIL_UPDATE_SUCCESS,
  updateCurrentEBookUpdate,
  REQUEST_UPLOAD_EBOOK_UPDATE_SUCCESS,
  ON_CONFIRM_UPDATE_COURSE,
  updateLesson,
  fetchCreateQuiz,
  fetchUpdateQuiz,
  REQUEST_UPDATE_QUIZ_DETAIL_SUCCESS,
  REQUEST_CREATE_QUIZ_DETAIL_SUCCESS,
  fetchDeleteQuiz,
  REQUEST_UPDATE_COURSE_SUCCESS,
  REQUEST_INIT_COURSE_DETAIL_SUCCESS,
  REQUEST_MULTI_QUESTION_DETAIL_SUCCESS,
} from '.'
import { showToastAlert } from '../app'
import { updateCourseMessage } from 'utils/validation'
import { PAGINATION_DEFAULT_LIMIT, PAGINATION_DEFAULT_PAGE } from 'utils/constants'
import { CourseType } from 'utils/enum'
import { redirect } from 'redux-first-router'
import { ROUTE_COURSE_MANAGEMENT } from '../routing'

function* initPage() {
  const { location } = yield select()
  const { payload } = location
  const { id } = payload
  const { courseManagementUpdate } = yield select()
  const { courseDataUpdate } = courseManagementUpdate
  yield put(
    updateCourseDataUpdate({
      ...courseDataUpdate,
      generalInformation: {
        ...courseDataUpdate.generalInformation,
        thumbnail: '',
      },
      listDeleteQuizzes: [],
    }),
  )

  yield put(fetchInitCourseDetail({ id: id }))

  yield put(fetchInitQuiz({ courseId: id }))
}

function* listenEvents() {
  const { location } = yield select()
  const { payload } = location
  const { id } = payload

  yield takeLatest(REQUEST_INIT_COURSE_DETAIL_SUCCESS, function* (action) {
    const { location } = yield select()
    const { payload } = location
    const { id } = payload
    yield put(
      fetchInitLesson({
        limit: PAGINATION_DEFAULT_LIMIT,
        page: PAGINATION_DEFAULT_PAGE,
        courseId: id,
      }),
    )
  })

  yield takeLatest(CONFIRM_UPDATE_LESSON_ACTION, function* (action) {
    yield put(handleUpdateLesson(action.payload))
  })

  yield takeLatest(REQUEST_UPDATE_LESSON_SUCCESS, function* (action) {
    yield put(showToastAlert(action.payload.message))
    yield put(handleFetchListLesson({ limit: 1, page: 100, courseId: id }))
  })

  yield takeLatest(REQUEST_UPDATE_LESSON_FAILURED, function* (action) {
    yield put(showToastAlert(action.payload.data.message, 'error'))
  })

  yield takeLatest(CONFIRM_CREATE_LESSON_ACTION, function* (action) {
    yield put(handleCreateLesson(action.payload))
  })

  yield takeLatest(REQUEST_CREATE_LESSON_SUCCESS, function* (action) {
    yield put(showToastAlert(action.payload.message))
    yield put(handleFetchListLesson({ limit: 1, page: 100, courseId: id }))
  })

  yield takeLatest(REQUEST_FETCH_LIST_LESSON_SUCCESS, function* (action) {
    const { courseManagementUpdate } = yield select()
    const { courseDataUpdate } = courseManagementUpdate
    if (action.payload.data[0]?.link?.toLowerCase()?.includes('pdf')) {
      yield put(
        updateCourseDataUpdate({
          ...courseDataUpdate,
          ebook: {
            ...action.payload.data[0],
          },
        }),
      )
    }
  })

  yield takeLatest(CONFIRM_DELETE_LESSON_ACTION, function* (action) {
    if (action.payload?.id) {
      yield put(handleDeleteLesson(action.payload))
    }
  })

  yield takeLatest(REQUEST_DELETE_LESSON_SUCCESS, function* (action) {
    yield put(showToastAlert(action.payload.message))
  })

  yield takeLatest(CONFIRM_UPDATE_COURSE_ACTION, function* (action) {
    yield put(handleUpdateCourse(action.payload))
  })

  yield takeLatest(REQUEST_CREATE_LESSON_FAILURED, function* (action) {
    yield put(showToastAlert(action.payload.data.message, 'error'))
  })

  yield takeLatest(UPLOAD_PHOTO_UPDATE_COURSE_ACTION, function* (action) {
    yield put(handleUploadUpdateFile({ action: action.payload, type: 'course' }))
  })

  yield takeLatest(UPLOAD_PHOTO_UPDATE_LESSON_ACTION, function* (action) {
    yield put(handleUploadUpdateFile({ action: action.payload, type: 'lesson' }))
  })

  yield takeLatest(REQUEST_UPLOAD_FILE_UPDATE_LESSON_SUCCESS, function* (action) {
    const { courseManagementUpdate } = yield select()
    const { videoDetailUpdate } = courseManagementUpdate
    yield put(
      updateVideoDetailUpdateData({
        ...videoDetailUpdate,
        thumbnail: action?.payload?.data[0]?.link,
      }),
    )
  })

  yield takeLatest(REQUEST_UPLOAD_FILE_UPDATE_COURSE_SUCCESS, function* (action) {
    const { courseManagementUpdate } = yield select()
    const { courseDataUpdate } = courseManagementUpdate
    yield put(
      updateCourseDataUpdate({
        ...courseDataUpdate,
        generalInformation: {
          ...courseDataUpdate?.generalInformation,
          thumbnail: action?.payload?.data[0]?.link,
        },
      }),
    )
  })

  yield takeLatest(ON_CONFIRM_UPDATE_COURSE, function* (action) {
    const { location } = yield select()
    const { payload } = location
    const { id } = payload
    const courseInfo = action.payload
    const { generalInformation, ebook, videos, quizzes, listDeleteQuizzes } = courseInfo

    // update general information
    yield put(
      handleUpdateCourse({
        ...courseInfo,
        page: courseInfo?.ebook?.page,
        video: courseInfo?.videos?.length,
      }),
    )

    // update lessons
    yield put(
      updateLesson({
        courseId: id,
        lessons: generalInformation.type === CourseType.EBOOK ? [ebook] : videos,
      }),
    )

    // update quizzes
    for (let i = 0; i < quizzes.length; i++) {
      const { questions, isNew, location } = quizzes[i]
      if (isNew) {
        yield put(
          fetchCreateQuiz({
            courseId: id,
            questions,
            location: quizzes[i].location,
            theLast: i === quizzes.length - 1,
            isNew,
          }),
        )
      } else {
        yield put(
          fetchUpdateQuiz({
            quizId: quizzes[i].id,
            location: location,
            questions,
            theLast: i === quizzes.length - 1,
            isNew,
          }),
        )
      }
    }

    // delete exist quizzes
    if (listDeleteQuizzes.length) {
      for (let i = 0; i < listDeleteQuizzes.length; i++) {
        yield put(fetchDeleteQuiz(listDeleteQuizzes[i]))
      }
    }
  })

  yield takeEvery(
    [REQUEST_CREATE_QUIZ_DETAIL_SUCCESS, REQUEST_UPDATE_QUIZ_DETAIL_SUCCESS],
    function* (action) {
      const state = yield select()
      const { courseManagementUpdate } = state
      const { updatingCourse, totalProcessingQuiz } = courseManagementUpdate
      const { questions, isNew } = action.meta.variables
      const quizId = isNew ? action.payload.data._id : action.meta.variables.quizId
      yield put(fetchMultiQuestion({ quizId, questions }))
      if (action.meta?.variables?.theLast && !updatingCourse && totalProcessingQuiz === 0) {
        yield put(redirect({ type: ROUTE_COURSE_MANAGEMENT }))
        yield put(showToastAlert(updateCourseMessage))
      }
    },
  )

  yield takeLatest(REQUEST_UPDATE_COURSE_SUCCESS, function* (action) {
    const state = yield select()
    const { courseManagementUpdate } = state
    const { courseDataUpdate, totalProcessingQuiz } = courseManagementUpdate
    const { creatingOrUpdatingQuiz } = courseDataUpdate
    if (!creatingOrUpdatingQuiz && totalProcessingQuiz === 0) {
      yield put(redirect({ type: ROUTE_COURSE_MANAGEMENT }))
      yield put(showToastAlert(updateCourseMessage))
    }
  })

  yield takeEvery(REQUEST_MULTI_QUESTION_DETAIL_SUCCESS, function* (action) {
    const state = yield select()
    const { courseManagementUpdate } = state
    const { courseDataUpdate, totalProcessingQuiz, updatingCourse } = courseManagementUpdate
    const { creatingOrUpdatingQuiz } = courseDataUpdate
    if (!creatingOrUpdatingQuiz && totalProcessingQuiz === 0 && !updatingCourse) {
      yield put(redirect({ type: ROUTE_COURSE_MANAGEMENT }))
      yield put(showToastAlert(updateCourseMessage))
    }
  })
}

function* ebookUpdateEvents() {
  yield takeLatest([THUMBNAIL_SELECTED_UPDATE, EBOOK_SELECTED_UPDATE], function* (action) {
    const file = action.payload
    yield put(
      ebookUploadFileUpdate({
        file,
        metadata: { type: action.type === THUMBNAIL_SELECTED_UPDATE ? 'thumbnail' : 'ebook' },
      }),
    )
  })

  yield takeLatest(REQUEST_UPLOAD_EBOOK_THUMBNAIL_UPDATE_SUCCESS, function* (action) {
    const { courseManagementCreate } = yield select()
    yield put(
      updateCurrentEBookUpdate({
        ...courseManagementCreate.currentEbook,
        thumbnail: action.payload.data[0]?.link,
        isChanged: true,
      }),
    )
  })

  yield takeLatest(REQUEST_UPLOAD_EBOOK_UPDATE_SUCCESS, function* (action) {
    const { courseManagementCreate } = yield select()
    yield put(
      updateCurrentEBookUpdate({
        ...courseManagementCreate.currentEbook,
        link: action.payload.data[0]?.link,
        isChanged: true,
      }),
    )
  })
}

export default function* loadUpdateCourserManagement() {
  yield all([fork(initPage), fork(listenEvents), fork(ebookUpdateEvents)])
}
