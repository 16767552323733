import { all, fork, put, select, takeLatest } from 'redux-saga/effects'
import {
  fetchDetailsListing,
  handleUpdateDetailsListing,
  REQUEST_UPDATE_DETAILS_LISTING_SUCCESS,
  UPDATE_AGENT_ACTION,
  CONFIRM_HOUSE_SOLD_ACTION,
  handleConfirmHouseSold,
  REQUEST_CONFIRM_HOUSE_SOLD_FAILURE,
  REQUEST_CONFIRM_HOUSE_SOLD_SUCCESS,
  REQUEST_UPDATE_DETAILS_LISTING_FAILURE,
} from '.'
import { showToastAlert } from '../app'
import { ROUTE_LISTING_MANAGEMENT } from '../routing'
import { fetchUserManagement, SEARCH_ACTION } from '../userManagement'
import { redirect } from 'redux-first-router'

function* initPage() {
  const { location } = yield select()
  const { payload } = location
  const { id } = payload
  yield put(fetchDetailsListing(id))
}

export function* listEvents() {
  yield takeLatest(UPDATE_AGENT_ACTION, function* (action) {
    const { payload } = action
    const { location } = yield select()

    yield put(handleUpdateDetailsListing({ id: location.payload.id, payload: payload }))
  })
  yield takeLatest(CONFIRM_HOUSE_SOLD_ACTION, function* (action) {
    yield put(handleConfirmHouseSold(action.payload))
  })
  yield takeLatest(REQUEST_CONFIRM_HOUSE_SOLD_SUCCESS, function* (action) {
    yield put(showToastAlert(action.payload.message))
    yield put(redirect({ type: ROUTE_LISTING_MANAGEMENT }))
  })
  yield takeLatest(REQUEST_CONFIRM_HOUSE_SOLD_FAILURE, function* (action) {
    yield put(showToastAlert(action.payload.data.message, 'error'))
  })

  yield takeLatest(SEARCH_ACTION, function* (action) {
    yield put(fetchUserManagement(action.payload))
  })

  yield takeLatest(REQUEST_UPDATE_DETAILS_LISTING_SUCCESS, function* (action) {
    yield put(redirect({ type: ROUTE_LISTING_MANAGEMENT }))
    yield put(showToastAlert(action.payload.message))
  })
  yield takeLatest(
    [REQUEST_UPDATE_DETAILS_LISTING_FAILURE, REQUEST_CONFIRM_HOUSE_SOLD_FAILURE],
    function* (action) {
      yield put(showToastAlert(action.payload.data.message, 'error'))
    },
  )
}
export default function* loadDetailListing() {
  yield all([fork(initPage), fork(listEvents)])
}
