import { all, fork, put, takeLatest } from 'redux-saga/effects'
import {
  VENDOR_CREATE_ACTION,
  VENDOR_CREATE_REQUEST_SUCCESS,
  VENDOR_CREATE_REQUEST_FAILURE,
  VENDOR_CREATE_UPLOAD_PHOTO_ACTION,
  fetchCreateVendor,
  fetchCreateVendorUploadPhoto,
} from '.'
import { ROUTE_VENDOR_MANAGEMENT } from '../routing'
import { redirect } from 'redux-first-router'
import { showToastAlert } from '../app'

function* listenEvents() {
  yield takeLatest(VENDOR_CREATE_ACTION, function* (action) {
    const { payload } = action
    yield put(
      fetchCreateVendor({
        name: payload.name,
        thumbnail: payload.thumbnail,
        description: payload.description,
        token: payload.token,
        status: payload.status,
      }),
    )
  })

  yield takeLatest(VENDOR_CREATE_REQUEST_SUCCESS, function* (action) {
    yield put(redirect({ type: ROUTE_VENDOR_MANAGEMENT }))
    yield put(showToastAlert(action.payload.message))
  })

  yield takeLatest(VENDOR_CREATE_REQUEST_FAILURE, function* (action) {
    yield put(showToastAlert(action.payload.data.message, 'error'))
  })

  yield takeLatest(VENDOR_CREATE_UPLOAD_PHOTO_ACTION, function* (action) {
    yield put(fetchCreateVendorUploadPhoto(action.payload.file))
  })
}

export default function* loadVendorManagementCreate() {
  yield all([fork(listenEvents)])
}
