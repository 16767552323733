import { createReducer } from 'redux-create-reducer'
import callAPI from 'utils/callAPI'

export const REQUEST_NOTIFICATION_LIST = 'REQUEST_NOTIFICATION_LIST'
export const REQUEST_NOTIFICATION_LIST_SUCCESS = 'REQUEST_NOTIFICATION_LIST_SUCCESS'
export const REQUEST_NOTIFICATION_LIST_FAILURE = 'REQUEST_NOTIFICATION_LIST_FAILURE'

export const REQUEST_READ_NOTIFICATION = 'REQUEST_READ_NOTIFICATION'
export const REQUEST_READ_NOTIFICATION_SUCCESS = 'REQUEST_READ_NOTIFICATION_SUCCESS'
export const REQUEST_READ_NOTIFICATION_FAILURE = 'REQUEST_READ_NOTIFICATION_FAILURE'

export const FETCH_NOTIFICATION_LIST_ACTION = 'FETCH_NOTIFICATION_ACTION'
export const REQUEST_READ_NOTIFICATION_ACTION = 'REQUEST_READ_NOTIFICATION_ACTION'
export const HANDLE_CLOSE_NOTIFICATION_ACTION = 'HANDLE_CLOSE_NOTIFICATION_ACTION'
const defaultState = {
  notificationData: [],

  notifications: [],
  fetchingNotificationList: true,
  failedFetchingNotificationList: false,

  fetchingReadNotification: true,
  failedFetchingReadNotification: false,
}

const reducer = createReducer(defaultState, {
  [REQUEST_NOTIFICATION_LIST]: (state) => {
    return {
      ...state,
      fetchingNotificationList: true,
      failedFetchingNotificationList: false,
    }
  },

  [REQUEST_NOTIFICATION_LIST_SUCCESS]: (state, action) => {
    return {
      ...state,
      notificationData: state.notificationData.length
        ? state.notificationData.concat(action.payload)
        : action.payload,
      notifications: state.notifications.length
        ? state.notifications.concat(action.payload.data)
        : action.payload.data,
      fetchingNotificationList: false,
      failedFetchingNotificationList: false,
    }
  },

  [REQUEST_NOTIFICATION_LIST_FAILURE]: (state) => {
    return {
      ...state,
      fetchingNotificationList: false,
      failedFetchingNotificationList: true,
    }
  },

  [REQUEST_READ_NOTIFICATION]: (state) => {
    return {
      ...state,
      fetchingReadNotification: true,
      failedFetchingReadNotification: false,
    }
  },

  [HANDLE_CLOSE_NOTIFICATION_ACTION]: (state) => {
    return {
      ...state,
      notifications: [],
      fetchingNotificationList: true,
      failedFetchingNotificationList: false,
    }
  },
})

export default reducer
export const namespace = 'notification'

export const fetchNotificationAction = (filters) => ({
  type: FETCH_NOTIFICATION_LIST_ACTION,
  payload: filters,
})

export const updateReadNotificationAction = (filters) => ({
  type: REQUEST_READ_NOTIFICATION_ACTION,
  payload: filters,
})

export const clearNotification = () => ({
  type: HANDLE_CLOSE_NOTIFICATION_ACTION,
})
export const fetchNotification = (filters) => async (dispatch, getState) => {
  return await callAPI({
    method: 'get',
    apiPath: '/notification/list',
    actionTypes: [
      REQUEST_NOTIFICATION_LIST,
      REQUEST_NOTIFICATION_LIST_SUCCESS,
      REQUEST_NOTIFICATION_LIST_FAILURE,
    ],
    variables: { ...filters },
    dispatch,
    getState,
  })
}

export const updateReadNotification = (id) => async (dispatch, getState) => {
  return await callAPI({
    method: 'put',
    apiPath: `/notification/read`,
    actionTypes: [
      REQUEST_READ_NOTIFICATION,
      REQUEST_READ_NOTIFICATION_SUCCESS,
      REQUEST_READ_NOTIFICATION_FAILURE,
    ],
    variables: id,
    dispatch,
    getState,
  })
}
