import { createReducer } from 'redux-create-reducer'
import callAPI from '../../../utils/callAPI'

export const VENDOR_DETAILS_REQUEST = 'VENDOR_DETAILS_REQUEST'
export const VENDOR_DETAILS_REQUEST_SUCCESS = 'VENDOR_DETAILS_REQUEST_SUCCESS'
export const VENDOR_DETAILS_REQUEST_FAILURE = 'VENDOR_DETAILS_REQUEST_FAILURE'

export const VENDOR_DETAILS_REVIEWS_REQUEST = 'VENDOR_DETAILS_REVIEWS_REQUEST'
export const VENDOR_DETAILS_REVIEWS_REQUEST_SUCCESS = 'VENDOR_DETAILS_REVIEWS_REQUEST_SUCCESS'
export const VENDOR_DETAILS_REVIEWS_REQUEST_FAILURE = 'VENDOR_DETAILS_REVIEWS_REQUEST_FAILURE'

export const VENDOR_DETAILS_UPDATE_ACTION = 'VENDOR_DETAILS_UPDATE_ACTION'
export const VENDOR_DETAILS_UPDATE_REQUEST = 'VENDOR_DETAILS_UPDATE_REQUEST'
export const VENDOR_DETAILS_UPDATE_REQUEST_SUCCESS = 'VENDOR_DETAILS_UPDATE_REQUEST_SUCCESS'
export const VENDOR_DETAILS_UPDATE_REQUEST_FAILURE = 'VENDOR_DETAILS_UPDATE_REQUEST_FAILURE'

export const VENDOR_DETAILS_UPLOAD_PHOTO_REQUEST = 'VENDOR_DETAILS_UPLOAD_PHOTO_REQUEST'
export const VENDOR_DETAILS_UPLOAD_PHOTO_REQUEST_SUCCESS =
  'VENDOR_DETAILS_UPLOAD_PHOTO_REQUEST_SUCCESS'
export const VENDOR_DETAILS_UPLOAD_PHOTO_REQUEST_FAILURE =
  'VENDOR_DETAILS_UPLOAD_PHOTO_REQUEST_FAILURE'

export const VENDOR_DETAILS_CHANGE_FILTER_ACTION = 'VENDOR_DETAILS_CHANGE_FILTER_ACTION'
export const VENDOR_DETAILS_UPLOAD_PHOTO_ACTION = 'VENDOR_DETAILS_UPLOAD_PHOTO_ACTION'
export const VENDOR_DETAILS_CHANGE_STATUS_ACTION = 'VENDOR_DETAILS_CHANGE_STATUS_ACTION'
export const VENDOR_DETAILS_CHANGE_STATUS_REQUEST = 'VENDOR_DETAILS_CHANGE_STATUS_REQUEST'
export const VENDOR_DETAILS_CHANGE_STATUS_REQUEST_SUCCESS =
  'VENDOR_DETAILS_CHANGE_STATUS_REQUEST_SUCCESS'
export const VENDOR_DETAILS_CHANGE_STATUS_REQUEST_FAILURE =
  'VENDOR_DETAILS_CHANGE_STATUS_REQUEST_FAILURE'

const defaultState = {
  vendorDetails: {
    name: '',
    thumbnail: '',
    description: '',
    token: '',
    status: '',
  },
  reviews: {},
  fetchingVendorDetails: false,
  fetchingVendorDetailsFailed: false,
}

const reducer = createReducer(defaultState, {
  [VENDOR_DETAILS_REQUEST]: (state, action) => {
    return {
      ...state,
      fetchingVendorDetails: true,
      fetchingVendorDetailsFailed: false,
    }
  },
  [VENDOR_DETAILS_REQUEST_SUCCESS]: (state, action) => {
    return {
      ...state,
      vendorDetails: action.payload.data,
      fetchingVendorDetails: false,
      fetchingVendorDetailsFailed: false,
    }
  },
  [VENDOR_DETAILS_REQUEST_FAILURE]: (state, action) => {
    return {
      ...state,
      fetchingVendorDetails: false,
      fetchingVendorDetailsFailed: true,
    }
  },
  [VENDOR_DETAILS_UPLOAD_PHOTO_REQUEST_SUCCESS]: (state, action) => {
    return {
      ...state,
      vendorDetails: {
        ...state.vendorDetails,
        thumbnail: action.payload.data[0].link,
      },
    }
  },
  [VENDOR_DETAILS_REVIEWS_REQUEST_SUCCESS]: (state, action) => {
    return {
      ...state,
      reviews: action.payload,
    }
  },
  [VENDOR_DETAILS_CHANGE_STATUS_REQUEST_SUCCESS]: (state, action) => {
    const { reviewId, status } = action.meta.variables
    const newReviews = [...state.reviews.data]
    const reviewIndex = newReviews.findIndex((review) => review._id === reviewId)
    newReviews[reviewIndex].status = status
    return {
      ...state,
      reviews: {
        ...state.reviews,
        data: [...newReviews],
      },
    }
  },
})

export default reducer
export const namespace = 'vendorManagementUpdate'

export const fetchVendorDetails = (id) => async (dispatch, getState) => {
  return await callAPI({
    method: 'get',
    apiPath: `/vendor-category/${id}`,
    actionTypes: [
      VENDOR_DETAILS_REQUEST,
      VENDOR_DETAILS_REQUEST_SUCCESS,
      VENDOR_DETAILS_REQUEST_FAILURE,
    ],
    dispatch,
    getState,
  })
}

export const fetchVendorReviews = (filters) => async (dispatch, getState) => {
  return await callAPI({
    method: 'get',
    apiPath: '/review',
    actionTypes: [
      VENDOR_DETAILS_REVIEWS_REQUEST,
      VENDOR_DETAILS_REVIEWS_REQUEST_SUCCESS,
      VENDOR_DETAILS_REVIEWS_REQUEST_FAILURE,
    ],
    variables: filters,
    dispatch,
    getState,
  })
}

export const fetchVendorDetailsUpdate = (vendorInfo) => async (dispatch, getState) => {
  return await callAPI({
    method: 'put',
    apiPath: `/vendor-category/${vendorInfo.id}`,
    actionTypes: [
      VENDOR_DETAILS_UPDATE_REQUEST,
      VENDOR_DETAILS_UPDATE_REQUEST_SUCCESS,
      VENDOR_DETAILS_UPDATE_REQUEST_FAILURE,
    ],
    variables: { ...vendorInfo },
    dispatch,
    getState,
  })
}

export const fetchVendorDetailsUploadPhoto = (file) => async (dispatch, getState) => {
  let formData = new FormData()
  formData.append('file', file)
  return await callAPI({
    method: 'post',
    apiPath: '/file/upload-files',
    actionTypes: [
      VENDOR_DETAILS_UPLOAD_PHOTO_REQUEST,
      VENDOR_DETAILS_UPLOAD_PHOTO_REQUEST_SUCCESS,
      VENDOR_DETAILS_UPLOAD_PHOTO_REQUEST_FAILURE,
    ],
    variables: formData,
    dispatch,
    getState,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export const fetchVendorDetailsChangeStatus =
  ({ reviewId, status }) =>
  async (dispatch, getState) => {
    return await callAPI({
      method: 'put',
      apiPath: `review/${reviewId}`,
      actionTypes: [
        VENDOR_DETAILS_CHANGE_STATUS_REQUEST,
        VENDOR_DETAILS_CHANGE_STATUS_REQUEST_SUCCESS,
        VENDOR_DETAILS_CHANGE_STATUS_REQUEST_FAILURE,
      ],
      variables: { reviewId, status },
      dispatch,
      getState,
    })
  }

export const uploadPhotoUpdateVendorAction = (credentials) => ({
  type: VENDOR_DETAILS_UPLOAD_PHOTO_ACTION,
  payload: credentials,
})

export const updateVendorAction = (payload) => ({
  type: VENDOR_DETAILS_UPDATE_ACTION,
  payload,
})

export const changeReviewFilterAction = (filters) => ({
  type: VENDOR_DETAILS_CHANGE_FILTER_ACTION,
  payload: filters,
})

export const changeReviewStatusAction = ({ reviewId, status }) => ({
  type: VENDOR_DETAILS_CHANGE_STATUS_ACTION,
  payload: { reviewId, status },
})
