import { createReducer } from 'redux-create-reducer'
import callAPI from '../../../utils/callAPI'
import { CourseType } from 'utils/enum'
import { TypeOfUser } from 'utils/constants'

export const CREATE_COURSE_REQUEST = 'CREATE_COURSE_REQUEST'
export const CREATE_COURSE_REQUEST_SUCCESS = 'CREATE_COURSE_REQUEST_SUCCESS'
export const CREATE_COURSE_REQUEST_FAILURE = 'CREATE_COURSE_REQUEST_FAILURE'

export const CREATE_QUIZ_REQUEST = 'CREATE_QUIZ_REQUEST'
export const CREATE_QUIZ_REQUEST_SUCCESS = 'CREATE_QUIZ_REQUEST_SUCCESS'
export const CREATE_QUIZ_REQUEST_FAILURE = 'CREATE_QUIZ_REQUEST_FAILURE'

export const CREATE_MULTI_QUESTION_REQUEST = 'CREATE_MULTI_QUESTION_REQUEST'
export const CREATE_MULTI_QUESTION_REQUEST_SUCCESS = 'CREATE_MULTI_QUESTION_REQUEST_SUCCESS'
export const CREATE_MULTI_QUESTION_REQUEST_FAILURE = 'CREATE_MULTI_QUESTION_REQUEST_FAILURE'

export const CREATE_MULTI_LESSON_REQUEST = 'CREATE_MULTI_LESSON_REQUEST'
export const CREATE_MULTI_LESSON_REQUEST_SUCCESS = 'CREATE_MULTI_LESSON_REQUEST_SUCCESS'
export const CREATE_MULTI_LESSON_REQUEST_FAILURE = 'CREATE_MULTI_LESSON_REQUEST_FAILURE'

export const CREATE_COURSE_ACTION = 'CREATE_COURSE_ACTION'

export const REQUEST_DELETE_QUIZ = 'REQUEST_DELETE_QUIZ'
export const REQUEST_DELETE_QUIZ_SUCCESS = 'REQUEST_DELETE_QUIZ_SUCCESS'
export const REQUEST_DELETE_QUIZ_FAILURE = 'REQUEST_DELETE_QUIZ_FAILURE'

export const REQUEST_UPDATE_QUIZ = 'REQUEST_UPDATE_QUIZ'
export const REQUEST_UPDATE_QUIZ_SUCCESS = 'REQUEST_UPDATE_QUIZ_SUCCESS'
export const REQUEST_UPDATE_QUIZ_FAILURE = 'REQUEST_UPDATE_QUIZ_FAILURE'

export const REQUEST_CREATE_QUESTION = 'REQUEST_CREATE_QUESTION'
export const REQUEST_CREATE_QUESTION_SUCCESS = 'REQUEST_CREATE_QUESTION_SUCCESS'
export const REQUEST_CREATE_QUESTION_FAILURE = 'REQUEST_CREATE_QUESTION_FAILURE'

export const REQUEST_DELETE_QUESTION = 'REQUEST_DELETE_QUESTION'
export const REQUEST_DELETE_QUESTION_SUCCESS = 'REQUEST_DELETE_QUESTION_SUCCESS'
export const REQUEST_DELETE_QUESTION_FAILURE = 'REQUEST_DELETE_QUESTION_FAILURE'

export const REQUEST_UPDATE_QUESTION = 'REQUEST_UPDATE_QUESTION'
export const REQUEST_UPDATE_QUESTION_SUCCESS = 'REQUEST_UPDATE_QUESTION_SUCCESS'
export const REQUEST_UPDATE_QUESTION_FAILURE = 'REQUEST_UPDATE_QUESTION_FAILURE'

export const REQUEST_MULTI_QUESTION = 'REQUEST_MULTI_QUESTION'
export const REQUEST_MULTI_QUESTION_SUCCESS = 'REQUEST_MULTI_QUESTION_SUCCESS'
export const REQUEST_MULTI_QUESTION_FAILURE = 'REQUEST_MULTI_QUESTION_FAILURE'

export const REQUEST_UPLOAD_EBOOK_THUMBNAIL = 'REQUEST_UPLOAD_EBOOK_THUMBNAIL'
export const REQUEST_UPLOAD_EBOOK_THUMBNAIL_SUCCESS = 'REQUEST_UPLOAD_EBOOK_THUMBNAIL_SUCCESS'
export const REQUEST_UPLOAD_EBOOK_THUMBNAIL_FAILURED = 'REQUEST_UPLOAD_EBOOK_THUMBNAIL_FAILURED'

export const REQUEST_UPLOAD_EBOOK = 'REQUEST_UPLOAD_EBOOK'
export const REQUEST_UPLOAD_EBOOK_SUCCESS = 'REQUEST_UPLOAD_EBOOK_SUCCESS'
export const REQUEST_UPLOAD_EBOOK_FAILURED = 'REQUEST_UPLOAD_EBOOK_FAILURED'

export const DELETE_QUIZ_ACTION = 'DELETE_QUIZ_ACTION'
export const DELETE_QUESTION_ACTION = 'DELETE_QUESTION_ACTION'

export const CREATE_QUIZ_ACTION = 'CREATE_QUIZ_ACTION'

export const REQUEST_FETCH_LESSON_DETAIL = 'REQUEST_FETCH_LESSON'
export const REQUEST_FETCH_LESSON_DETAIL_SUCCESS = 'REQUEST_FETCH_LESSON_DETAIL_SUCCESS'
export const REQUEST_FETCH_LESSON_DETAIL_FAILURED = 'REQUEST_FETCH_LESSON_DETAIL_FAILURED'

export const REQUEST_FETCH_LIST_LESSON = 'REQUEST_FETCH_LIST_LESSON'
export const REQUEST_FETCH_LIST_LESSON_SUCCESS = 'REQUEST_FETCH_LIST_LESSON_SUCCESS'
export const REQUEST_FETCH_LIST_LESSON_FAILURED = 'REQUEST_FETCH_LIST_LESSON_FAILURED'

export const REQUEST_UPDATE_LESSON = 'REQUEST_UPDATE_LESSON'
export const REQUEST_UPDATE_LESSON_SUCCESS = 'REQUEST_UPDATE_LESSON_SUCCESS'
export const REQUEST_UPDATE_LESSON_FAILURED = 'REQUEST_UPDATE_LESSON_FAILURED'

export const REQUEST_CREATE_LESSON = 'REQUEST_CREATE_LESSON'
export const REQUEST_CREATE_LESSON_SUCCESS = 'REQUEST_CREATE_LESSON_SUCCESS'
export const REQUEST_CREATE_LESSON_FAILURED = 'REQUEST_CREATE_LESSON_FAILURED'

export const REQUEST_DELETE_LESSON = 'REQUEST_DELETE_LESSON'
export const REQUEST_DELETE_LESSON_SUCCESS = 'REQUEST_DELETE_LESSON_SUCCESS'
export const REQUEST_DELETE_LESSON_FAILURED = 'REQUEST_DELETE_LESSON_FAILURED'

export const REQUEST_UPLOAD_FILE_CREATE_LESSON = 'REQUEST_UPLOAD_FILE_CREATE_LESSON'
export const REQUEST_UPLOAD_FILE_CREATE_LESSON_SUCCESS = 'REQUEST_UPLOAD_FILE_CREATE_LESSON_SUCCESS'
export const REQUEST_UPLOAD_FILE_CREATE_LESSON_FAILURED =
  'REQUEST_UPLOAD_FILE_CREATE_LESSON_FAILURED'

export const REQUEST_UPLOAD_FILE_CREATE_COURSE = 'REQUEST_UPLOAD_FILE_CREATE_COURSE'
export const REQUEST_UPLOAD_FILE_CREATE_COURSE_SUCCESS = 'REQUEST_UPLOAD_FILE_CREATE_COURSE_SUCCESS'
export const REQUEST_UPLOAD_FILE_CREATE_COURSE_FAILURED =
  'REQUEST_UPLOAD_FILE_CREATE_COURSE_FAILURED'

export const CONFIRM_UPDATE_COURSE_ACTION = 'CONFIRM_UPDATE_COURSE_ACTION'
export const CONFIRM_UPDATE_LESSON_ACTION = 'CONFIRM_UPDATE_LESSON_ACTION'
export const CONFIRM_CREATE_LESSON_ACTION = 'CONFIRM_CREATE_LESSON_ACTION'
export const UPLOAD_PHOTO_CREATE_LESSON_ACTION = 'UPLOAD_PHOTO_CREATE_LESSON_ACTION'
export const UPLOAD_PHOTO_CREATE_COURSE_ACTION = 'UPLOAD_PHOTO_CREATE_COURSE_ACTION'
export const CHANGE_TOTAL_QUIZ_CREATE_ACTION = 'CHANGE_TOTAL_QUIZ_CREATE_ACTION'
export const RESET_STATE_ACTION = 'RESET_STATE_ACTION'
export const CLEAR_CREATE_LESSON_AVATAR = 'CLEAR_CREATE_LESSON_AVATAR'

export const UPDATE_CREATE_COURSE_DATA = 'UPDATE_CREATE_COURSE_DATA'
export const UPDATE_CREATE_VIDEO_DETAIL = 'UPDATE_CREATE_VIDEO_DETAIL'
export const CLEAR_CREATE_VIDEO_DETAIL = 'CLEAR_CREATE_VIDEO_DETAIL'

export const CONFIRM_UPLOAD_PDF_AND_CREATE_LESSON = 'CONFIRM_UPLOAD_PDF_AND_CREATE_LESSON'
export const UPLOAD_PDF_LESSON_INFOR_CREATE_LESSON = 'UPLOAD_PDF_LESSON_INFOR_CREATE_LESSON'
export const UPDATE_CURRENT_EBOOK = 'UPDATE_CURRENT_EBOOK'
export const THUMBNAIL_SELECTED = 'THUMBNAIL_SELECTED'
export const EBOOK_SELECTED = 'EBOOK_SELECTED'
export const CONFIRM_EBOOK_FORM = 'CONFIRM_EBOOK_FORM'
export const SET_CURRENT_EBOOK_DATA = 'SET_CURRENT_EBOOK_DATA'
export const CHANGE_COURSE_TYPE_ACTION = 'CHANGE_COURSE_TYPE_ACTION'

const defaultState = {
  courseData: {
    courseId: '',
    generalInformation: {
      type: CourseType.VIDEO,
      userType: TypeOfUser.User,
    },
    ebook: null,
    videos: [],
    quizzes: [],
  },
  uploadPDFLessonInforCreateLesson: [],

  currentEbook: {},

  uploadingThumbnail: false,
  failedUploadingThumbnail: false,

  uploadingEbook: false,
  failedUploadingEbook: false,

  videoDetailCreate: {},

  fetchingUpdateCourse: false,
  failedFetchingUpdateCourse: false,

  updateLessonData: [],
  fetchingUpdateLesson: false,
  failedFetchingUpdateLesson: false,

  fetchingCreateLesson: false,
  failedFetchingCreateLesson: false,

  lessonDetail: [],
  fetchingLessonDetail: false,
  failedFetchingLessonDetail: false,

  lessonList: [],
  fetchingLessonList: false,
  failedFetchingLessonList: false,

  thumbnailLessonData: [],
  fetchingThumbnailLessonData: false,
  failedFetchingThumbnailLessonData: false,

  fetchingUploadAvatar: false,
  failedFetchingUploadAvatar: false,
}

const reducer = createReducer(defaultState, {
  [CHANGE_COURSE_TYPE_ACTION]: (state, action) => ({
    ...state,
    courseData: {
      ...state.courseData,
      generalInformation: {
        ...state.courseData.generalInformation,
        type: action.payload,
      },
      ebook: null,
      videos: [],
    },
  }),
  [RESET_STATE_ACTION]: (state, action) => ({
    ...defaultState,
    courseData: {
      courseId: '',
      generalInformation: {
        type: CourseType.VIDEO,
        userType: TypeOfUser.User,
      },
      ebook: null,
      videos: [],
      quizzes: [],
    },
  }),
  [CREATE_COURSE_ACTION]: (state, action) => ({
    ...defaultState,
    courseData: {
      ...action.payload,
    },
  }),

  [UPLOAD_PDF_LESSON_INFOR_CREATE_LESSON]: (state, action) => {
    return {
      ...state,
      uploadPDFLessonInforCreateLesson: action.payload,
    }
  },
  [CLEAR_CREATE_LESSON_AVATAR]: (state, action) => {
    return {
      ...state,
      thumbnailLessonData: action.payload,
    }
  },
  [CREATE_COURSE_REQUEST_SUCCESS]: (state, action) => {
    return {
      ...state,
      courseData: {
        ...state.courseData,
        courseId: action.payload.data._id,
      },
    }
  },

  [REQUEST_UPDATE_LESSON]: (state, action) => {
    return {
      ...state,
      fetchingUpdateLesson: true,
      failedFetchingUpdateLesson: false,
    }
  },
  [REQUEST_UPDATE_LESSON_SUCCESS]: (state, action) => {
    return {
      ...state,
      updateLessonData: action.payload,
      fetchingUpdateLesson: false,
      failedFetchingUpdateLesson: false,
    }
  },
  [REQUEST_UPDATE_LESSON_FAILURED]: (state, action) => {
    return {
      ...state,
      fetchingCreateUser: false,
      failedFetchingCreateUser: true,
    }
  },
  [REQUEST_FETCH_LIST_LESSON]: (state, action) => {
    return {
      ...state,
      fetchingLessonList: true,
      failedFetchingLessonList: false,
    }
  },
  [REQUEST_FETCH_LIST_LESSON_SUCCESS]: (state, action) => {
    return {
      ...state,
      lessonList: action.payload,
      fetchingLessonList: false,
      failedFetchingLessonList: false,
    }
  },
  [REQUEST_FETCH_LIST_LESSON_FAILURED]: (state, action) => {
    return {
      ...state,
      fetchingLessonList: false,
      failedFetchingLessonList: true,
    }
  },
  [REQUEST_FETCH_LESSON_DETAIL]: (state, action) => {
    return {
      ...state,
      fetchingLessonDetail: true,
      failedFetchingLessonDetail: false,
    }
  },
  [REQUEST_FETCH_LESSON_DETAIL_SUCCESS]: (state, action) => {
    return {
      ...state,
      lessonDetail: action.payload,
      fetchingLessonDetail: false,
      failedFetchingLessonDetail: false,
    }
  },
  [REQUEST_FETCH_LESSON_DETAIL_FAILURED]: (state, action) => {
    return {
      ...state,
      fetchingLessonDetail: false,
      failedFetchingLessonDetail: true,
    }
  },
  [REQUEST_CREATE_LESSON]: (state, action) => {
    return {
      ...state,
      fetchingCreateLesson: true,
      failedFetchingCreateLesson: false,
    }
  },
  [REQUEST_CREATE_LESSON_SUCCESS]: (state, action) => {
    return {
      ...state,
      fetchingCreateLesson: false,
      failedFetchingCreateLesson: false,
      courseData: {
        ...state.courseData,
        actionAfterCreateCourse: '',
      },
    }
  },
  [REQUEST_CREATE_LESSON_FAILURED]: (state, action) => {
    return {
      ...state,
      fetchingCreateLesson: false,
      failedFetchingCreateLesson: true,
    }
  },
  [REQUEST_UPLOAD_FILE_CREATE_LESSON]: (state, action) => {
    return {
      ...state,
      fetchingThumbnailLessonData: true,
      failedFetchingThumbnailLessonData: false,
    }
  },
  [REQUEST_UPLOAD_FILE_CREATE_LESSON_SUCCESS]: (state, action) => {
    return {
      ...state,
      thumbnailLessonData: action.payload.data,
      videoDetailCreate: {
        ...state.videoDetailCreate,
        thumbnail: action.payload.data?.[0].link,
      },
      fetchingThumbnailLessonData: false,
      failedFetchingThumbnailLessonData: false,
    }
  },
  [REQUEST_UPLOAD_FILE_CREATE_LESSON_FAILURED]: (state, action) => {
    return {
      ...state,
      fetchingThumbnailLessonData: false,
      failedFetchingThumbnailLessonData: true,
    }
  },
  [REQUEST_UPLOAD_FILE_CREATE_COURSE]: (state, action) => {
    return {
      ...state,
      fetchingUploadAvatar: true,
      failedFetchingUploadAvatar: false,
    }
  },
  [REQUEST_UPLOAD_FILE_CREATE_COURSE_SUCCESS]: (state, action) => {
    return {
      ...state,
      courseData: {
        ...state.courseData,
        generalInformation: {
          ...state.generalInformation,
          thumbnail: action?.payload?.data[0]?.link,
        },
      },
      fetchingUploadAvatar: false,
      failedFetchingUploadAvatar: false,
    }
  },
  [REQUEST_UPLOAD_FILE_CREATE_COURSE_FAILURED]: (state, action) => {
    return {
      ...state,
      fetchingUploadAvatar: false,
      failedFetchingUploadAvatar: true,
    }
  },
  [UPDATE_CREATE_COURSE_DATA]: (state, action) => {
    return {
      ...state,
      courseData: action.payload,
    }
  },
  [UPDATE_CREATE_VIDEO_DETAIL]: (state, action) => {
    return {
      ...state,
      videoDetailCreate: action.payload,
    }
  },
  [CLEAR_CREATE_VIDEO_DETAIL]: (state, action) => {
    return {
      ...state,
      videoDetailCreate: {},
    }
  },

  [REQUEST_UPLOAD_EBOOK_THUMBNAIL]: (state, action) => ({
    ...state,
    uploadingThumbnail: true,
    failedUploadingThumbnail: false,
  }),
  [REQUEST_UPLOAD_EBOOK_THUMBNAIL_SUCCESS]: (state, action) => ({
    ...state,
    uploadingThumbnail: false,
    failedUploadingThumbnail: false,
  }),
  [REQUEST_UPLOAD_EBOOK_THUMBNAIL_FAILURED]: (state, action) => ({
    ...state,
    uploadingThumbnail: false,
    failedUploadingThumbnail: true,
  }),

  [REQUEST_UPLOAD_EBOOK]: (state, action) => ({
    ...state,
    uploadingEbook: true,
    failedUploadingEbook: false,
  }),
  [REQUEST_UPLOAD_EBOOK_SUCCESS]: (state, action) => ({
    ...state,
    uploadingEbook: false,
    failedUploadingEbook: false,
  }),
  [REQUEST_UPLOAD_EBOOK_FAILURED]: (state, action) => ({
    ...state,
    uploadingEbook: false,
    failedUploadingEbook: true,
  }),

  [UPDATE_CURRENT_EBOOK]: (state, action) => {
    return {
      ...state,
      currentEbook: {
        ...action.payload,
      },
    }
  },

  [CONFIRM_EBOOK_FORM]: (state, action) => ({
    ...state,
    courseData: {
      ...state.courseData,
      ebook: action.payload,
    },
    currentEbook: {},
  }),
  [SET_CURRENT_EBOOK_DATA]: (state, action) => ({
    ...state,
    currentEbook: action.payload,
  }),
})

export default reducer
export const namespace = 'courseManagementCreate'

export const changeCourseTypeAction = (courseType) => ({
  type: CHANGE_COURSE_TYPE_ACTION,
  payload: courseType,
})

export const createQuizAction = () => ({
  type: CREATE_QUIZ_ACTION,
})

export const fetchCreateCourse = (courseData) => async (dispatch, getState) => {
  return await callAPI({
    method: 'post',
    apiPath: '/course',
    actionTypes: [
      CREATE_COURSE_REQUEST,
      CREATE_COURSE_REQUEST_SUCCESS,
      CREATE_COURSE_REQUEST_FAILURE,
    ],
    variables: courseData,
    dispatch,
    getState,
  })
}

export const fetchCreateQuiz = (params) => async (dispatch, getState) => {
  return await callAPI({
    method: 'post',
    apiPath: '/quiz',
    actionTypes: [CREATE_QUIZ_REQUEST, CREATE_QUIZ_REQUEST_SUCCESS, CREATE_QUIZ_REQUEST_FAILURE],
    variables: params,
    dispatch,
    getState,
  })
}

export const fetchMultiQuestion =
  ({ quizId, questions }) =>
  async (dispatch, getState) => {
    return await callAPI({
      method: 'post',
      apiPath: 'question/multi',
      actionTypes: [
        CREATE_MULTI_QUESTION_REQUEST,
        CREATE_MULTI_QUESTION_REQUEST_SUCCESS,
        CREATE_MULTI_QUESTION_REQUEST_FAILURE,
      ],
      variables: { quizId, questions },
      dispatch,
      getState,
    })
  }

export const fetchMultiLessonCreate =
  ({ courseId, lessons }) =>
  async (dispatch, getState) => {
    return await callAPI({
      method: 'post',
      apiPath: 'lesson/multi',
      actionTypes: [
        CREATE_MULTI_LESSON_REQUEST,
        CREATE_MULTI_LESSON_REQUEST_SUCCESS,
        CREATE_MULTI_LESSON_REQUEST_FAILURE,
      ],
      variables: { courseId, lessons },
      dispatch,
      getState,
    })
  }

export const handleFetchListLesson = (filters) => async (dispatch, getState) => {
  return await callAPI({
    method: 'get',
    apiPath: `/lesson`,
    actionTypes: [
      REQUEST_FETCH_LIST_LESSON,
      REQUEST_FETCH_LIST_LESSON_SUCCESS,
      REQUEST_FETCH_LIST_LESSON_FAILURED,
    ],
    variables: { ...filters },
    dispatch,
    getState,
  })
}

export const handleUpdateLesson =
  ({ lessonInfo }) =>
  async (dispatch, getState) => {
    return await callAPI({
      method: 'put',
      apiPath: `/lesson/${lessonInfo.id}`,
      actionTypes: [
        REQUEST_UPDATE_LESSON,
        REQUEST_UPDATE_LESSON_SUCCESS,
        REQUEST_UPDATE_LESSON_FAILURED,
      ],
      variables: {
        ...lessonInfo,
      },
      dispatch,
      getState,
    })
  }

export const fetchLessonCreate = (lessonInfo) => async (dispatch, getState) => {
  return await callAPI({
    method: 'post',
    apiPath: '/lesson',
    actionTypes: [
      REQUEST_CREATE_LESSON,
      REQUEST_CREATE_LESSON_SUCCESS,
      REQUEST_CREATE_LESSON_FAILURED,
    ],
    variables: lessonInfo,
    dispatch,
    getState,
  })
}
export const handleDeleteLesson =
  ({ id }) =>
  async (dispatch, getState) => {
    return await callAPI({
      method: 'delete',
      apiPath: `/lesson/${id}`,
      actionTypes: [
        REQUEST_DELETE_LESSON,
        REQUEST_DELETE_LESSON_SUCCESS,
        REQUEST_DELETE_LESSON_FAILURED,
      ],
      dispatch,
      getState,
    })
  }

export const handleUploadCreateFile = (data) => async (dispatch, getState) => {
  let formData = new FormData()
  formData.append('file', data.action)
  return await callAPI({
    method: 'post',
    apiPath: '/file/upload-files',
    actionTypes:
      data.type === 'lesson'
        ? [
            REQUEST_UPLOAD_FILE_CREATE_LESSON,
            REQUEST_UPLOAD_FILE_CREATE_LESSON_SUCCESS,
            REQUEST_UPLOAD_FILE_CREATE_LESSON_FAILURED,
          ]
        : [
            REQUEST_UPLOAD_FILE_CREATE_COURSE,
            REQUEST_UPLOAD_FILE_CREATE_COURSE_SUCCESS,
            REQUEST_UPLOAD_FILE_CREATE_COURSE_FAILURED,
          ],
    variables: formData,
    dispatch,
    getState,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export const confirmUpdateLessonAction = (credentials) => ({
  type: CONFIRM_UPDATE_LESSON_ACTION,
  payload: credentials,
})

export const confirmCreateLessonAction = (credentials) => ({
  type: CONFIRM_CREATE_LESSON_ACTION,
  payload: credentials,
})

export const confirmUploadAndCreateLessonAction = (credentials) => ({
  type: CONFIRM_UPLOAD_PDF_AND_CREATE_LESSON,
  payload: credentials,
})

export const confirmUpdateCourseAction = ({ courseInfo }) => ({
  type: CONFIRM_UPDATE_COURSE_ACTION,
  payload: { courseInfo },
})

export const uploadPDFCreateLessonAction = (credentials) => ({
  type: UPLOAD_PHOTO_CREATE_LESSON_ACTION,
  payload: credentials,
})

export const uploadCreateCourseAvatarAction = (credentials) => ({
  type: UPLOAD_PHOTO_CREATE_COURSE_ACTION,
  payload: credentials,
})

export const resetStateAction = () => ({
  type: RESET_STATE_ACTION,
})

export const clearCreateLessonAvatarAction = () => ({
  type: CLEAR_CREATE_LESSON_AVATAR,
})

export const handleUploadPDFLessonInforCreateLesson = (info) => ({
  type: UPLOAD_PDF_LESSON_INFOR_CREATE_LESSON,
  payload: info,
})

export const updateCourseData = (data) => ({
  type: UPDATE_CREATE_COURSE_DATA,
  payload: data,
})

export const updateVideoDetailCreateData = (data) => ({
  type: UPDATE_CREATE_VIDEO_DETAIL,
  payload: data,
})

export const clearVideoDetailCreateData = (data) => ({
  type: CLEAR_CREATE_VIDEO_DETAIL,
  payload: data,
})

export const createCourseAction = (courseData) => ({
  type: CREATE_COURSE_ACTION,
  payload: courseData,
})

export const changeTotalQuizCreateAction = (newTotal) => ({
  type: CHANGE_TOTAL_QUIZ_CREATE_ACTION,
  payload: newTotal,
})

export const thumbnailSelected = (payload) => ({
  type: THUMBNAIL_SELECTED,
  payload: payload,
})

export const ebookSelected = (payload) => ({
  type: EBOOK_SELECTED,
  payload: payload,
})

export const submitEbookForm = (payload) => ({
  type: CONFIRM_EBOOK_FORM,
  payload: payload,
})

export const setCurrentEbookData = (payload) => ({
  type: SET_CURRENT_EBOOK_DATA,
  payload: payload,
})

export const updateCurrentEBook = (payload) => ({
  type: UPDATE_CURRENT_EBOOK,
  payload: payload,
})

export const ebookUploadFile =
  ({ file, metadata }) =>
  async (dispatch, getState) => {
    let formData = new FormData()
    formData.append('file', file)
    formData.append('metadata', metadata)
    return await callAPI({
      method: 'post',
      apiPath: '/file/upload-files',
      actionTypes:
        metadata.type === 'thumbnail'
          ? [
              REQUEST_UPLOAD_EBOOK_THUMBNAIL,
              REQUEST_UPLOAD_EBOOK_THUMBNAIL_SUCCESS,
              REQUEST_UPLOAD_EBOOK_THUMBNAIL_FAILURED,
            ]
          : [REQUEST_UPLOAD_EBOOK, REQUEST_UPLOAD_EBOOK_SUCCESS, REQUEST_UPLOAD_EBOOK_FAILURED],
      variables: formData,
      dispatch,
      getState,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }
